var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "verify-phone-code-box p-2" }, [
    _c("h4", [
      _vm._v(
        " " +
          _vm._s(
            _vm._f("t")(
              _vm.userData.phone
                ? "Verify Phone Number"
                : "Verify your email address"
            )
          ) +
          " "
      ),
      _c("br"),
    ]),
    _c("div", [
      _c("span", [_vm._v(" " + _vm._s(_vm.$t("Verify Code Sent to")) + ":")]),
      _c("small", { staticClass: "text-primary mx-1", attrs: { dir: "ltr" } }, [
        _vm._v(
          _vm._s(
            _vm.userData.phone
              ? _vm.formatePhoneNumber(_vm.userData.phone)
              : _vm.userData.email
          )
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "inputs d-flex flex-row justify-content-center mt-2",
        class: _vm.error ? "error" : "",
        attrs: { dir: "ltr", id: "otp" },
      },
      _vm._l(_vm.verificationCodeLength, function (i) {
        return _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.code[i],
              expression: "code[i]",
            },
          ],
          key: i,
          staticClass: "m-2 text-center form-control rounded phone_code_inputs",
          attrs: {
            type: "number",
            id: `phone_code_input${i}`,
            indx: i,
            autocomplete: "new-password",
            disabled: _vm.loading2 || _vm.loading,
            onkeypress: "return !['e'].includes(event.key)",
            onfocus: "this.select()",
          },
          domProps: { value: _vm.code[i] },
          on: {
            blur: function ($event) {
              return _vm.inputenter($event)
            },
            keyup: function ($event) {
              return _vm.inputenter($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.code, i, $event.target.value)
            },
          },
        })
      }),
      0
    ),
    _vm.errorText
      ? _c("small", { staticClass: "text-danger d-block mb-2 text-center" }, [
          _vm._v(_vm._s(_vm.errorText)),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "text-center mt-4" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn btn-primary px-5",
            class: _vm.btnClass || "py-3 rounded-btn",
            attrs: {
              variant: "primary",
              id: "verifyCodeBtn",
              disabled: _vm.loading2,
            },
            on: { click: _vm.verifyCode },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("t")(_vm.loading2 ? "Verifing code..." : "Verify")
                ) +
                " "
            ),
            _vm.loading2
              ? _c("b-spinner", { staticClass: "mx-1", attrs: { small: "" } })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "mt-3 content d-flex justify-content-center align-items-center",
      },
      [
        _c(
          "a",
          {
            staticClass: "text-decoration-none text-dark mx-2",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.resend.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("t")(
                    _vm.loading ? "Sending" : "Resend the verfication code"
                  )
                ) +
                " "
            ),
            _vm.loading ? _c("span", [_vm._v("...")]) : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }