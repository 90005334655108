var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form-root mb-15",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveCompany.apply(null, arguments)
        },
      },
    },
    [
      _c("h2", { staticClass: "title-header" }, [
        _c("strong", [
          _vm._v(" " + _vm._s(_vm._f("t")("Set up your Business")) + " "),
        ]),
      ]),
      _c("div", { staticClass: "mb-15" }, [
        _c(
          "label",
          { staticClass: "primary", attrs: { for: "BusinessName" } },
          [_vm._v(_vm._s(_vm._f("t")("Business name")))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.BusinessName,
              expression: "BusinessName",
            },
          ],
          staticClass: "full-line",
          attrs: {
            disabled: _vm.loading,
            type: "text",
            required: "",
            id: "BusinessName",
          },
          domProps: { value: _vm.BusinessName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.BusinessName = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "mb-15" }, [
        _c("label", { staticClass: "primary", attrs: { for: "Website" } }, [
          _vm._v(_vm._s(_vm._f("t")("Business namespace"))),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.company_key,
              expression: "form.company_key",
            },
          ],
          staticClass: "full-line",
          attrs: {
            disabled: _vm.loading,
            type: "text",
            required: "",
            id: "Website",
          },
          domProps: { value: _vm.form.company_key },
          on: {
            keyup: _vm.modifyKey,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "company_key", $event.target.value)
            },
          },
        }),
        _vm.form.company_key
          ? _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.form.company_key) + "." + _vm._s(_vm.base)),
            ])
          : _vm._e(),
      ]),
      !_vm.form.info.noWebsite
        ? _c("div", { staticClass: "mb-15" }, [
            _c("label", { staticClass: "primary", attrs: { for: "Website" } }, [
              _vm._v(_vm._s(_vm._f("t")("Website name"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.info.website,
                  expression: "form.info.website",
                },
              ],
              staticClass: "full-line",
              attrs: {
                disabled: _vm.loading,
                type: "text",
                required: "",
                id: "Website",
              },
              domProps: { value: _vm.form.info.website },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form.info, "website", $event.target.value)
                },
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mb-1 d-flex align-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.info.noWebsite,
              expression: "form.info.noWebsite",
            },
          ],
          attrs: { disabled: _vm.loading, type: "checkbox", id: "noWebsite" },
          domProps: {
            checked: Array.isArray(_vm.form.info.noWebsite)
              ? _vm._i(_vm.form.info.noWebsite, null) > -1
              : _vm.form.info.noWebsite,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.form.info.noWebsite,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form.info, "noWebsite", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form.info,
                        "noWebsite",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form.info, "noWebsite", $$c)
                }
              },
              function ($event) {
                _vm.form.info.website = ""
                _vm.$forceUpdate()
              },
            ],
          },
        }),
        _c("label", { staticClass: "hint-msg", attrs: { for: "noWebsite" } }, [
          _vm._v(" " + _vm._s(_vm._f("t")("I don't have a Website")) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mb-15" },
        [
          _c("label", { staticClass: "primary" }, [
            _vm._v(" " + _vm._s(_vm._f("t")("Country")) + " "),
          ]),
          _c("Countries", {
            attrs: { loading: _vm.loading, required: "required" },
            on: { select: _vm.setCountryCode },
          }),
        ],
        1
      ),
      _vm.countryCode
        ? _c("div", { staticClass: "mb-15" }, [
            _c(
              "label",
              { staticClass: "primary", attrs: { for: "Phonenumber" } },
              [_vm._v(" " + _vm._s(_vm._f("t")("Phone number")) + " ")]
            ),
            _c("div", { staticClass: "input-group" }, [
              _c("span", { staticClass: "code" }, [
                _vm._v(" +" + _vm._s(_vm.countryCode) + " "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone",
                  },
                ],
                staticClass: "full-line",
                staticStyle: { width: "auto" },
                attrs: {
                  disabled: _vm.loading,
                  type: "number",
                  required: "",
                  id: "Phonenumber",
                },
                domProps: { value: _vm.phone },
                on: {
                  blur: function ($event) {
                    _vm.form.admin.phone =
                      _vm.countryCode.toString() + _vm.phone
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.phone = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c("b-col", [
            _c("h4", { staticClass: "title-header" }, [
              _c("strong", [
                _vm._v(
                  " " + _vm._s(_vm._f("t")("Administrator Login info")) + " "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-1" },
        [
          _c("b-col", [
            _c("label", { staticClass: "primary", attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm._f("t")("Email"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.admin.email,
                  expression: "form.admin.email",
                },
              ],
              staticClass: "full-line",
              attrs: {
                type: "email",
                required: "",
                disabled: _vm.loading,
                id: "emailadd",
                placeholder: _vm.$t("Email"),
              },
              domProps: { value: _vm.form.admin.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form.admin, "email", $event.target.value)
                },
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-1" },
        [
          _c("b-col", [
            _c("label", { staticClass: "primary", attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm._f("t")("Password"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.admin.password,
                  expression: "form.admin.password",
                },
              ],
              staticClass: "full-line",
              attrs: {
                type: "password",
                required: "",
                disabled: _vm.loading,
                id: "emailadd",
                placeholder: _vm.$t("Password"),
              },
              domProps: { value: _vm.form.admin.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form.admin, "password", $event.target.value)
                },
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-warning btn-lg mb-15 d-block",
          attrs: { type: "submit" },
        },
        [_vm._v(" " + _vm._s(_vm._f("t")("Set up your Business")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }