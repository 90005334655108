export default {
    namespaced: true,
    state: {
        businesses: {}
    },
    actions: {
        async create({dispatch}, params)
        {
            return await this._vm.loadFromURL(dispatch, null, null, "companiesCreate", params, null);
        },
        gotoBusiness({rootState}, business)
        {
            console.log("business gotoBusiness", business);

            if(business.urls && business.urls[0])
            {      
                //1- access the dashboard by api by current token (rootState.User.token)          
                //2- window.open(business.urls[0]+process.env.VUE_APP_DASHBOARD_URL, "_blank");
                console.log("business.urls[0]+process.env.VUE_APP_DASHBOARD_URL:", business.urls[0]+process.env.VUE_APP_DASHBOARD_URL)
                window.open(business.urls[0]+process.env.VUE_APP_DASHBOARD_URL, "_blank")
            }
            // else
        }
    }
}