export default {
    namespaced: true,
    state: {
        storage: {
            key: process.env.VUE_APP_STORE_KEY || 'DmmlA5HjAaaAfomxd3xjC1lDs5lzwZOg'
        },
        Http: {
            mainURL: process.env.VUE_APP_MAIN_API,
            domainURL: null,
            mainApiPath: process.env.VUE_APP_MAIN_API_PATH || "/v1/",            
        },
        dashboardPath: process.env.VUE_APP_DASHBOARD_URL || "/dashboard",
    },
    getters: {
        get: (state) => (key, sub_key) => {
            let value = key ? (state[key] || null) : state;
            if (sub_key && value) value = value[sub_key] || null;

            return value;
        }
    },
    actions: {
        async setConfig({ state }, payload) {
            if (!payload.module || !payload.key) {
                console.error("Can't save config data with provided payload", payload);
                return null;
            }

            if (payload.sub_key) {
                if (!state[payload.module][payload.key]) state[payload.module][payload.key] = {};
                state[payload.module][payload.key][payload.sub_key] = payload.value || null;
            }
            else state[payload.module][payload.key] = payload.value || null;

            return state[payload.module][payload.key];


        },
        async get({ getters }, payload) {
            if (typeof payload != String)
                return await getters.get(payload.key, payload.sub_key || null);
            else
                return await getters.get(payload);
        },
        url({state}) {
            return state.Http.mainURL + state.Http.mainApiPath;
        }
    }
}