export default {
    namespaced: true,
    state: {
        settings: {},
    },
   
    actions: {
        async init({ dispatch }) {
            await dispatch("load");
            await dispatch("Locale/onChangeEvent", {
                key: 'reloadSettings', callBack: (context) => {
                    dispatch("load");
                }
            }, { root: true });
        },

        async load({ state, dispatch, getters }) {
            try {
                let response = await dispatch("Http/post", { name: 'settings'}, { root: true });
                state.settings = response.results || {};
                if (response.results) {
                    document.title = response.results.AppName;
                }
            }
            catch (error) {
                console.error("Settings error:", "Can't load settings from API >> ", error);
                state.settings = {};
            }
        },
        get({ state }, key) {
            return state.settings ? state.settings[key] || null : null;
        }
    }
}