var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v(" " + _vm._s(_vm._f("t")("Need Help?")) + " "),
    _c("a", { attrs: { href: "#" } }, [
      _vm._v(" " + _vm._s(_vm._f("t")("Content support")) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }