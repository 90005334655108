<template>
    <div>
        <div v-if="!selectedValue" style="position: relative;">       
            <input type="text" style="padding: 26px;" v-model="searchText" @keyup="findword" 
                :disabled="!countries || loading" class="form-control form-control-lg dropdown-toggle" :required="required" 
                @focus="menu = true;$forceUpdate()" 
                @blur="hideList" 
                aria-label="Text input with dropdown button">
            <div class="dropdown-menu" :class="{'show': menu}" v-if="countries" style="overflow: auto;max-height: 150px;">
                <a class="dropdown-item" href="#" @click.prevent="setVal(country)" v-for="(country, i) in filtered" :key="i">
                    {{getFlagByCode(country.info ? country.info.code_alpha2 : null)}}
                    {{country.name}}
                </a>
            </div>
        </div>
        <b-row v-else>
            <b-col>
                <b-alert show dismiss-label="x" variant="info" @dismissed="clear" dismissible>
                    {{getFlagByCode(selectedValue.info ? selectedValue.info.code_alpha2 : null)}}
                    {{selectedValue.name}} <span v-if="selectedValue.info && showCode">({{selectedValue.info.nationality_id}})</span>
                </b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    props: [
        "required",
        "model",
        "loading",
        "showCode",
    ],
    data() {
        return {
            menu: false,
            filtered: [],
            searchText: "",
            selectedValue: null
        }
    },
    computed: {
        ...mapGetters("Areas", ["countries"])
    },
    methods: { 
        findword()
        {
            if(!this.searchText || this.searchText == "") 
            {
                this.filtered = this.countries;
                return;
            }
            this.filtered = this.filterList(this.searchText, this.countries || [], "name");
        },
        clear()
        {
            this.selectedValue = null;
            this.searchText = "";
            this.$emit('select', null);
            this.findword();
            this.$forceUpdate();
        },
        setVal(country)
        {
            this.selectedValue = country;
            this.$emit('select', country);
            this.$forceUpdate();
        },
        hideList()
        {
            window.setTimeout(() => {this.menu = false;this.$forceUpdate();}, 200);
        }
    },
    mounted() {
        this.searchText = "";
    },
    watch: {
        countries: {
            handler()
            {
                this.findword();
            },
            deep: true,
            immediate: true
        }
    },
}
</script>