var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrapper", class: _vm.$t("settings.dir") },
    [
      _c("div", { staticClass: "logo-side" }, [
        _c(
          "a",
          {
            attrs: { href: "/" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goToPage("/")
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/logo.png"),
                alt: "app logo",
              },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "forms-controler" },
        [
          _c("router-view"),
          _c(
            "div",
            [
              _c("br"),
              _c(
                "b-row",
                { staticClass: "algin-items-center" },
                [
                  _c(
                    "b-col",
                    { staticClass: "muted px-5 mt-2", attrs: { cols: "8" } },
                    [
                      _c("Logout", {
                        on: {
                          logout: function ($event) {
                            return _vm.goToPage({ name: "Home" })
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-end", attrs: { cols: "4" } },
                    [_c("Locales")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }