export default {
    login: "user/auth",
    logout: "user/logout",
    profile: "user/profile",
    updateProfile: "user/profile/update",
    loginByPhone: "register/phone",
    verifyPhone: "register/phone/verify",
    loginByEmail: "register/email",
    verifyEmail: "register/email/verify",
    settings: "settings",
    countries: "areas/0",
    companiesCreate: "company/create",
}