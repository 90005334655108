var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "a",
        {
          staticClass: "w-100 text-end",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.logout.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            _vm._s(_vm._f("t")(_vm.loading ? "Signing out..." : "Signout"))
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }