

<script>

export default {

  created() {
    let currentDomain = window.location.host;
    console.log("created => currentDomain:", currentDomain)
    console.log("created => currentDomain:", this.$route)
    if(this.checkForSubDomain(currentDomain)) {
      window.location.replace(`http://my_test_business.firnas.io/dashboard`)
      window.location.replace(`${window.location.href}`)
    } else {
      this.$router.replace({
        name: 'Home'
      })
    }
   },
  methods: {
    checkForSubDomain(url) {
      // url = url || 'my_test_business.firnas.io'; // just for the example
      // eslint-disable-next-line
      var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
      
      return !!url.match(regex); // make sure it returns boolean
    },
  },

}

</script>