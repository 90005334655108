<template>
    <div class="main-wrapper" :class="$t('settings.dir')">
        <div class="logo-side">
            <a href="/" @click.prevent="goToPage('/')"><img src="@/assets/images/logo.png" alt="app logo"></a>
        </div>
        <div class="forms-controler">
            <router-view></router-view>
            <!-- <p class="mb-15"></p>
            <p class="mb-15"></p>
            <p class="mb-15"></p>
            <p class="mb-15"></p> -->

            <div>
                
                <br />
                <b-row class="algin-items-center"> 
                    <b-col cols="8"  class="muted px-5 mt-2">
                       <!-- <Help /> -->
                       <Logout @logout="goToPage({name: 'Home'})" />
                    </b-col>
                    <b-col cols="4" class="text-end">
                        <Locales />
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Register from './Pages/Register.vue';
import Help from './Components/Forms/Help.vue';
import Locales from './Components/Forms/Locales.vue';
import Logout from './Components/Auth/Logout.vue';
export default {
  components: { Register, Help, Locales, Logout },
    name: "App",
    methods: {
        ...mapActions(["init"]),
    },
    async created() {
        await this.init();
        let currentUser = await this.user;
        let currentDomain = window.location.host;
        console.log("created => this.$route.name == ", this.$route.name == "Home")
        if(currentUser !== null && currentUser.companies.length > 0 && this.$route.name == "Home") {
            //* redirect to the first business
            let companyDomain = currentUser.companies[0].urls[0];
            window.open(`${companyDomain}/dashboard` , "_blank")
        }
    },
    // mounted() {
        
    // },
    computed: {
        ...mapGetters("User", ['token', 'user']),
    }
}
</script>

<style lang="scss">
    @import "~bootstrap";
    @import '~vue-toast-notification/dist/theme-sugar.css';
    @import "@/assets/scss/Register.css";
    .rtl
    {
        text-align: right;
        direction: rtl;
    }
</style>