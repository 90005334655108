var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.selectedValue
        ? _c("div", { staticStyle: { position: "relative" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText",
                },
              ],
              staticClass: "form-control form-control-lg dropdown-toggle",
              staticStyle: { padding: "26px" },
              attrs: {
                type: "text",
                disabled: !_vm.countries || _vm.loading,
                required: _vm.required,
                "aria-label": "Text input with dropdown button",
              },
              domProps: { value: _vm.searchText },
              on: {
                keyup: _vm.findword,
                focus: function ($event) {
                  _vm.menu = true
                  _vm.$forceUpdate()
                },
                blur: _vm.hideList,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchText = $event.target.value
                },
              },
            }),
            _vm.countries
              ? _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    class: { show: _vm.menu },
                    staticStyle: { overflow: "auto", "max-height": "150px" },
                  },
                  _vm._l(_vm.filtered, function (country, i) {
                    return _c(
                      "a",
                      {
                        key: i,
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setVal(country)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getFlagByCode(
                                country.info ? country.info.code_alpha2 : null
                              )
                            ) +
                            " " +
                            _vm._s(country.name) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: "",
                        "dismiss-label": "x",
                        variant: "info",
                        dismissible: "",
                      },
                      on: { dismissed: _vm.clear },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getFlagByCode(
                              _vm.selectedValue.info
                                ? _vm.selectedValue.info.code_alpha2
                                : null
                            )
                          ) +
                          " " +
                          _vm._s(_vm.selectedValue.name) +
                          " "
                      ),
                      _vm.selectedValue.info && _vm.showCode
                        ? _c("span", [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.selectedValue.info.nationality_id) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }