var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.user
        ? _c("Register")
        : _c("div", { staticClass: "verify" }, [
            _c("h2", { staticClass: "title-header" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("t")("Welcome")) +
                  ", " +
                  _vm._s(_vm.user.first_name) +
                  " "
              ),
            ]),
            _c("h5", [
              _vm._v(" " + _vm._s("Redirecting to Your Company...") + " "),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-outline btn-sm my-4",
                attrs: { type: "button" },
                on: { click: _vm.redirectToCompany },
              },
              [_vm._v(" " + _vm._s("Click here If Not redirected") + " ")]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }