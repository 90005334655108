var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-content-center flex-column continer",
      class: _vm.selected,
      staticStyle: { height: "75vh" },
    },
    [
      _c(
        "svg",
        {
          staticStyle: { "max-width": "600px", width: "100%" },
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 750 500",
          },
        },
        [
          _c(
            "g",
            { attrs: { id: "freepik--background-complete--inject-17" } },
            [
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M71.63,377.56s-2.9,6.8,13,11.55,24.77,6,24.77,6,3.4,1.4,1.79,3.85-6.73,6.35-5.06,9.72,6.06,4,12,2.15,19-5.29,20.2-5.6,3,.05,2.2,1.9-9.62,13.21-4,17.06,21.3-3.89,23-4.71,2.57-.6,3.18.19-2.71,4.32-5.08,7.65-4.53,7.61-1.09,9.56,16,.48,33.95-15l7.86-7.71-2.58-10.76c-6.94-22.67-16.76-30.61-20.71-30.8s-4.4,4.59-4.18,8.67,1.13,8.85.2,9.18-1.75.07-2.78-1.54-10.12-16.51-16.95-16.27-5.46,14.54-5.74,16.54-2.06,1.32-2.88.44-10.06-10.84-14.09-15.53-7.95-6.61-11.28-4.57-.95,8-.9,10.91-3.58,2.3-3.58,2.3-8.19-3.66-24.15-8.13-17.13,3-17.13,3",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M200.61,415.59c-11.71-3.32-32.42-9.58-50.23-14.85s-33.92-10-45.61-13.43l-13.85-4-3.77-1.08-1-.29-.33-.12.33.08,1,.26,3.78,1c3.29.89,8,2.22,13.88,3.88,11.71,3.33,27.85,8.05,45.65,13.33S189,412,200.68,415.36",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M113.48,403.55a3.64,3.64,0,0,1,.69-.41c.45-.24,1.11-.58,1.93-1,1.64-.81,3.94-1.86,6.49-3l6.55-2.85,2-.87a3.5,3.5,0,0,1,.74-.29,4,4,0,0,1-.68.41c-.45.24-1.11.58-1.94,1-1.65.79-4,1.82-6.51,2.94l-6.53,2.89-2,.88A3.28,3.28,0,0,1,113.48,403.55Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M131.87,394.14c-.08,0-2.47-4.6-5.33-10.36s-5.1-10.47-5-10.52,2.47,4.6,5.33,10.36S132,394.09,131.87,394.14Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M158.85,403.47a4.47,4.47,0,0,1-.18-.79c-.1-.58-.24-1.31-.39-2.17-.33-1.84-.8-4.37-1.42-7.15s-1.25-5.27-1.73-7.07c-.23-.85-.42-1.57-.57-2.14a4,4,0,0,1-.17-.8,4.31,4.31,0,0,1,.3.76c.17.5.4,1.21.67,2.11.54,1.79,1.22,4.28,1.84,7.07s1.05,5.33,1.32,7.18c.13.92.23,1.67.28,2.19A2.92,2.92,0,0,1,158.85,403.47Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M144.09,415.65a3,3,0,0,1,.53-.53c.41-.38.92-.83,1.52-1.37,1.3-1.13,3.1-2.69,5.11-4.39s3.84-3.22,5.18-4.31l1.59-1.28a3.4,3.4,0,0,1,.62-.44,3.75,3.75,0,0,1-.53.54l-1.52,1.36c-1.3,1.14-3.11,2.69-5.12,4.39s-3.83,3.23-5.17,4.32l-1.6,1.28A3.14,3.14,0,0,1,144.09,415.65Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M166.86,426.92a4.81,4.81,0,0,1,.68-.68l2-1.75c1.65-1.47,3.95-3.48,6.5-5.68s4.88-4.19,6.58-5.61l2-1.67a5.28,5.28,0,0,1,.77-.57,5.13,5.13,0,0,1-.68.67l-1.95,1.76c-1.65,1.46-3.95,3.47-6.5,5.68s-4.88,4.18-6.57,5.6l-2,1.67A4.73,4.73,0,0,1,166.86,426.92Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M184.8,411.21a6.23,6.23,0,0,1-.25-1c-.16-.71-.35-1.63-.6-2.74-.5-2.32-1.21-5.51-2.09-9s-1.75-6.65-2.4-8.93c-.31-1.09-.57-2-.76-2.7a6.31,6.31,0,0,1-.25-1,7.38,7.38,0,0,1,.37,1c.22.62.52,1.53.88,2.66.7,2.26,1.62,5.41,2.5,8.92s1.55,6.71,2,9c.22,1.16.39,2.11.49,2.76A5.16,5.16,0,0,1,184.8,411.21Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M523,202.12c-14-9.39-17.4-27.21-17.14-43.3,0-2.68-.3-5.51.93-7.88s4.19-4,6.61-2.95c2,.9,2.92,3.2,4.07,5.1a15.62,15.62,0,0,0,6.4,5.9c1.72.86,3.84,1.36,5.51.4,2.3-1.32,2.51-4.52,2.45-7.19l-.36-14.92a24.85,24.85,0,0,1,.67-7.94c.78-2.55,2.57-4.93,5.1-5.69s5.69.65,6.27,3.26a26.1,26.1,0,0,1,.14,3.32,2.79,2.79,0,0,0,1.69,2.61c1.07.3,2.12-.48,2.91-1.27,2.7-2.75,4.51-6.25,6.2-9.73s3.31-7,5.76-10,5.86-5.38,9.68-5.66,7.93,2,9,5.74-1.1,7.6-3.43,10.68a59.49,59.49,0,0,1-12.19,12.05,4.94,4.94,0,0,0-2,2.26c-.55,1.79,1.17,3.53,2.94,4.08,2,.63,4.15.39,6.24.59s4.35,1.09,5.18,3c1.14,2.71-1.09,5.61-3.31,7.52a50.84,50.84,0,0,1-14.94,9,17.15,17.15,0,0,0-5.57,2.83c-1.56,1.4-2.47,3.82-1.48,5.68s3.46,2.42,5.51,2,3.87-1.57,5.81-2.34c3.65-1.45,8.39-1.28,10.79,1.84a8.46,8.46,0,0,1,1,7.46,19.34,19.34,0,0,1-3.93,6.66A51.3,51.3,0,0,1,547.82,201c-8.46,3.1-16.33,4-24.84,1.09",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M527,192a240.18,240.18,0,0,1,9-25.91c.92-2.25,1.82-4.48,2.72-6.67s1.78-4.36,2.78-6.42a119.87,119.87,0,0,1,6.44-11.56c2.22-3.57,4.46-6.89,6.58-10s4.16-5.91,6.13-8.41a94.33,94.33,0,0,1,10.14-11.11c1.29-1.21,2.33-2.12,3-2.72l.81-.69a2.07,2.07,0,0,1,.29-.22,1.65,1.65,0,0,1-.26.26l-.78.72c-.69.63-1.7,1.56-3,2.79a99.29,99.29,0,0,0-10,11.19c-1.94,2.51-4,5.34-6.07,8.43s-4.32,6.4-6.53,10a120.15,120.15,0,0,0-6.38,11.52c-1,2.06-1.87,4.21-2.77,6.4l-2.72,6.67a244.75,244.75,0,0,0-9.05,25.85",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M541.63,152.94a5.41,5.41,0,0,1-.28-1.13c-.15-.74-.34-1.81-.55-3.13-.42-2.65-.88-6.33-1.38-10.39s-1-7.73-1.46-10.36c-.23-1.32-.43-2.39-.57-3.12a5.69,5.69,0,0,1-.17-1.16,5.2,5.2,0,0,1,.35,1.12c.21.72.45,1.78.73,3.09.55,2.63,1.12,6.3,1.61,10.37s.91,7.63,1.24,10.4c.15,1.25.29,2.3.39,3.15A4.81,4.81,0,0,1,541.63,152.94Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M575.83,145.92a6.72,6.72,0,0,1-1.36.19c-.88.1-2.16.23-3.72.44-3.14.39-7.45,1.09-12.18,2.06s-9,2.06-12,2.93c-1.53.42-2.75.8-3.6,1.05a6.17,6.17,0,0,1-1.34.35,7.9,7.9,0,0,1,1.28-.53c.83-.31,2-.74,3.56-1.21,3-.95,7.26-2.08,12-3.07a119.11,119.11,0,0,1,12.24-1.93c1.58-.17,2.86-.25,3.74-.28A8.12,8.12,0,0,1,575.83,145.92Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M527.36,191.34a3.44,3.44,0,0,1-.24-.42c-.16-.33-.36-.74-.61-1.23-.52-1.07-1.24-2.64-2.1-4.59-1.72-3.9-3.94-9.37-6.3-15.43s-4.52-11.54-6.16-15.47c-.8-1.91-1.47-3.49-2-4.65l-.52-1.27a2,2,0,0,1-.16-.46s.1.14.25.42l.61,1.23c.52,1.07,1.24,2.64,2.1,4.59,1.72,3.9,3.94,9.36,6.3,15.43S523.09,181,524.73,185c.8,1.91,1.46,3.48,2,4.65l.52,1.27A1.31,1.31,0,0,1,527.36,191.34Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M572.7,175.83a1.6,1.6,0,0,1-.45.2l-1.32.47-4.85,1.66c-4.1,1.41-9.74,3.43-16,5.66s-11.89,4.19-16,5.52c-2.06.66-3.74,1.18-4.9,1.52l-1.35.38a2.31,2.31,0,0,1-.48.1,1.6,1.6,0,0,1,.45-.2l1.32-.47c1.22-.41,2.86-1,4.85-1.67,4.1-1.4,9.74-3.42,16-5.65s11.89-4.19,16-5.52c2.06-.66,3.74-1.18,4.9-1.52l1.35-.38A2.31,2.31,0,0,1,572.7,175.83Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M675.91,269.23a10.16,10.16,0,0,1-3.73,7,17.94,17.94,0,0,1-7.31,3.48c-5.11,1.28-11.11.35-16.15-1.19,2.06-5.47,8-8.78,11.1-10.26,4.92-2.35,15.25-3.61,16.09,1",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M642.43,286.36a8.66,8.66,0,0,1,8.68-.26,11.5,11.5,0,0,1,5.46,7,4.84,4.84,0,0,1-.26,4.07,3.72,3.72,0,0,1-3.5,1.37,7.54,7.54,0,0,1-3.53-1.64c-3.59-2.74-6.55-6.05-6.85-10.55",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M629.26,285.72a16.81,16.81,0,0,1,3.42-.64,16.06,16.06,0,0,1,3.81,0,35,35,0,0,1,4.59.71,17.36,17.36,0,0,1,7.52,3.78,12.75,12.75,0,0,1,1.71,1.92,2.68,2.68,0,0,1,.47.82c-.09.06-.82-1.07-2.45-2.42a18.72,18.72,0,0,0-7.39-3.53,43.51,43.51,0,0,0-4.51-.74,18.41,18.41,0,0,0-3.71-.12A22.77,22.77,0,0,1,629.26,285.72Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M668.14,270.88a10.56,10.56,0,0,1-1.59.78c-1,.46-2.53,1.12-4.4,1.91-3.72,1.58-8.9,3.71-14.67,5.93s-11.06,4.06-14.91,5.32c-1.93.63-3.49,1.12-4.58,1.44a9.8,9.8,0,0,1-1.71.44,10.23,10.23,0,0,1,1.64-.66l4.51-1.61c3.81-1.36,9.07-3.28,14.84-5.49L662,273.18l4.47-1.73A10,10,0,0,1,668.14,270.88Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M638.21,280.84A20.25,20.25,0,0,0,646,264.06c-.08-1.64-.59-3.55-2.13-4.09s-3.15.63-4.2,1.88a17.28,17.28,0,0,0-1.88,19.1",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M642.38,267.19a2.76,2.76,0,0,1,0,.93c0,.6-.1,1.45-.23,2.51a23,23,0,0,1-2.47,7.95,14.53,14.53,0,0,1-2.89,3.62,14.09,14.09,0,0,1-5.52,3,2.93,2.93,0,0,1-.91.18c0-.12,1.24-.47,3.09-1.44a14.9,14.9,0,0,0,3-2.12,14.48,14.48,0,0,0,2.74-3.5,25.53,25.53,0,0,0,2.57-7.73A31.86,31.86,0,0,1,642.38,267.19Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M108.27,97.34a12,12,0,0,1,8.85,3.18,21.21,21.21,0,0,1,5.32,8c2.37,5.76,2.3,12.94,1.36,19.1-6.76-1.47-11.64-7.85-13.91-11.25-3.59-5.36-6.82-17.23-1.62-19",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M134,133.63a10.27,10.27,0,0,1-1.79-10.13,13.6,13.6,0,0,1,7.28-7.58c1.52-.69,3.32-1.13,4.81-.39a4.36,4.36,0,0,1,2.2,3.86,8.86,8.86,0,0,1-1.32,4.42c-2.59,4.66-6,8.7-11.18,9.82",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M135.54,149.16a20.69,20.69,0,0,1-1.34-3.9,19.11,19.11,0,0,1-.66-4.46,42.3,42.3,0,0,1,.05-5.49,20.49,20.49,0,0,1,3.15-9.45,15.55,15.55,0,0,1,1.94-2.34c.54-.48.85-.71.89-.68s-1.11,1.14-2.41,3.28a22,22,0,0,0-2.88,9.25,49.7,49.7,0,0,0-.09,5.41,20.82,20.82,0,0,0,.49,4.36A28.42,28.42,0,0,1,135.54,149.16Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M111.53,106.17a11.14,11.14,0,0,1,1.18,1.71c.73,1.13,1.75,2.78,3,4.83,2.49,4.1,5.87,9.79,9.44,16.18s6.65,12.25,8.78,16.55c1.07,2.14,1.91,3.89,2.47,5.11a10.91,10.91,0,0,1,.8,1.92,12.89,12.89,0,0,1-1.05-1.8l-2.66-5c-2.24-4.23-5.38-10.06-8.95-16.43L115.28,113c-1.14-2-2.08-3.66-2.8-4.94A12.53,12.53,0,0,1,111.53,106.17Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M128.29,139.51a23.92,23.92,0,0,0-21-6.25c-1.9.37-4,1.29-4.42,3.19s1.27,3.57,2.91,4.6A20.47,20.47,0,0,0,128.5,140",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M111.61,137a3.19,3.19,0,0,1,1.08-.18c.7-.08,1.72-.13,3-.15a27,27,0,0,1,9.73,1.53,16.61,16.61,0,0,1,4.72,2.77,16.41,16.41,0,0,1,3,3.33,15.9,15.9,0,0,1,1.44,2.62,3.5,3.5,0,0,1,.37,1c-.13.06-.75-1.38-2.21-3.38a17.88,17.88,0,0,0-3-3.12,17.44,17.44,0,0,0-4.56-2.61,30.36,30.36,0,0,0-9.49-1.69A36.52,36.52,0,0,1,111.61,137Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M345.51,465.58a10.7,10.7,0,0,1,.05-8.38,18.8,18.8,0,0,1,5.12-6.85c4.14-3.7,10.19-5.77,15.66-6.82.76,6.13-3.15,12.13-5.33,15-3.44,4.62-12.46,10.86-15.5,7",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M368.37,433.13a9.1,9.1,0,0,1-8,4.51,12.11,12.11,0,0,1-8.54-3.87,5.09,5.09,0,0,1-1.75-3.93,3.9,3.9,0,0,1,2.59-3,7.91,7.91,0,0,1,4.1-.2c4.7.79,9.09,2.42,11.58,6.48",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M381,427.26a17.23,17.23,0,0,1-2.88,2.28,16.94,16.94,0,0,1-3.55,1.88,37.86,37.86,0,0,1-4.63,1.59,18.28,18.28,0,0,1-8.89.16,13.77,13.77,0,0,1-2.54-.94c-.56-.31-.85-.5-.83-.54s1.28.59,3.47,1.05a19.57,19.57,0,0,0,8.64-.33,48,48,0,0,0,4.57-1.52,18.34,18.34,0,0,0,3.52-1.72A28,28,0,0,1,381,427.26Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M352,460.22a11.12,11.12,0,0,1,1.1-1.51c.73-.94,1.82-2.29,3.17-3.94,2.7-3.31,6.49-7.84,10.8-12.75s8.33-9.22,11.31-12.29c1.48-1.54,2.7-2.77,3.56-3.6a11.11,11.11,0,0,1,1.38-1.25,10.44,10.44,0,0,1-1.2,1.42c-.89,1-2,2.22-3.42,3.73-2.9,3.14-6.86,7.51-11.17,12.41l-10.92,12.61-3.33,3.82A11,11,0,0,1,352,460.22Z",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#ebebeb" },
                attrs: {
                  d: "M375,436.22a21.37,21.37,0,0,0,1,19.49c.87,1.49,2.29,3,4,2.77s2.63-2.13,3-3.82a18.26,18.26,0,0,0-7.63-18.76",
                },
              }),
              _c("path", {
                staticStyle: { fill: "#e0e0e0" },
                attrs: {
                  d: "M377.83,451s-.21-.29-.48-.86-.61-1.4-1-2.45a24.28,24.28,0,0,1-1.6-8.64,15.28,15.28,0,0,1,.92-4.8,14.54,14.54,0,0,1,1.92-3.49,13.72,13.72,0,0,1,1.78-2c.46-.43.73-.65.76-.62s-.93,1-2.18,2.86a16.19,16.19,0,0,0-1.74,3.44,15.74,15.74,0,0,0-.84,4.61,27.22,27.22,0,0,0,1.4,8.49A31.3,31.3,0,0,1,377.83,451Z",
                },
              }),
            ]
          ),
          _c("g", { attrs: { id: "freepik--Stains--inject-17" } }, [
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M48.54,242.16c-4.21-24.3,4.24-51.14,23.63-66.36s49.53-16.35,68.32-.39c13.21,11.23,19.39,28.37,27.26,43.82s19.79,31.09,36.92,33.76c14.78,2.3,29.64-5.78,44.47-3.84,14.48,1.89,26.6,13.73,30.77,27.72s1,29.54-6.42,42.08-19,22.31-31.55,29.71c-40.49,23.81-94.22,23-134-1.93s-63.84-73-60-119.83",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.7000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M48.54,242.16c-4.21-24.3,4.24-51.14,23.63-66.36s49.53-16.35,68.32-.39c13.21,11.23,19.39,28.37,27.26,43.82s19.79,31.09,36.92,33.76c14.78,2.3,29.64-5.78,44.47-3.84,14.48,1.89,26.6,13.73,30.77,27.72s1,29.54-6.42,42.08-19,22.31-31.55,29.71c-40.49,23.81-94.22,23-134-1.93s-63.84-73-60-119.83",
                },
              }),
            ]),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M283.11,459.66c13.15-9,19.33-28.76,11.11-42.39h0c-8.32-12.4-21.24-22.29-36-24.38s-31.15,4.9-37.73,18.31c-4.15,8.44-4.16,18.64-.72,27.39a43.53,43.53,0,0,0,18.19,20.91C251.55,467.72,270,468.64,283.11,459.66Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.7000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M283.11,459.66c13.15-9,19.33-28.76,11.11-42.39h0c-8.32-12.4-21.24-22.29-36-24.38s-31.15,4.9-37.73,18.31c-4.15,8.44-4.16,18.64-.72,27.39a43.53,43.53,0,0,0,18.19,20.91C251.55,467.72,270,468.64,283.11,459.66Z",
                },
              }),
            ]),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M710.11,376.83c-.81-21.05-11.24-41.92-28.7-53.7s-41.77-13.39-59.76-2.44c-11.21,6.82-19.34,17.68-26.11,28.92-3.71,6.16-7.58,12.95-14.25,15.64-8.67,3.51-18.2-1.27-27.37-3.1a43.06,43.06,0,0,0-50,30.94c-8,33.19,15.27,56.88,40.15,71.26,27.81,16.08,63,16.06,93.24,5.34,19.85-7,38.35-18.6,51.87-34.75S710.93,397.88,710.11,376.83Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.7000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M710.11,376.83c-.81-21.05-11.24-41.92-28.7-53.7s-41.77-13.39-59.76-2.44c-11.21,6.82-19.34,17.68-26.11,28.92-3.71,6.16-7.58,12.95-14.25,15.64-8.67,3.51-18.2-1.27-27.37-3.1a43.06,43.06,0,0,0-50,30.94c-8,33.19,15.27,56.88,40.15,71.26,27.81,16.08,63,16.06,93.24,5.34,19.85-7,38.35-18.6,51.87-34.75S710.93,397.88,710.11,376.83Z",
                },
              }),
            ]),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M662.31,197c-2.57-16.8-18.77-31.9-35.68-30.28h0c-15.79,2.29-31.28,10.16-40.12,23.44s-9.67,32.25.2,44.78c6.21,7.89,16,12.62,26,13.35a46.48,46.48,0,0,0,28.53-7.86C655.49,231.13,664.88,213.82,662.31,197Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.7000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M662.31,197c-2.57-16.8-18.77-31.9-35.68-30.28h0c-15.79,2.29-31.28,10.16-40.12,23.44s-9.67,32.25.2,44.78c6.21,7.89,16,12.62,26,13.35a46.48,46.48,0,0,0,28.53-7.86C655.49,231.13,664.88,213.82,662.31,197Z",
                },
              }),
            ]),
          ]),
          _c("g", { attrs: { id: "freepik--character-2--inject-17" } }, [
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M466.52,201.23c2.18-.27,3.91-1.87,5.89-2.8a10.22,10.22,0,0,1,9.42.47c2.81,1.68,4.81,3.7,5,7l-3.67,4",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#f4d3bd" },
              attrs: {
                d: "M489.76,221.67l-5.9,35.41c-1,6-3.7,14.67-13.15,12.65h0c-8.07-2.37-8.86-10.55-7.92-16.17.45-2.67.82-4.89.84-5.17,0,0-8.87-2.35-8-11.38.41-4.36,2-13.29,3.48-21.34A16.9,16.9,0,0,1,477.44,202l.87.09C487.64,203.61,491.55,212.38,489.76,221.67Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#eaae94" },
              attrs: {
                d: "M463.63,248.26a21.1,21.1,0,0,0,12.11-2.11s-3.46,6.2-12.37,4.45Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M459.89,221.07c.18.16,1.4-.3,3.07-.1s2.84.93,3,.8-.08-.33-.59-.66a5.77,5.77,0,0,0-2.41-.82,5.16,5.16,0,0,0-2.46.26C460,220.76,459.8,221,459.89,221.07Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M473.59,222.6c.18.16,1.4-.29,3.06-.09s2.83.92,3,.8-.08-.33-.59-.66a6.08,6.08,0,0,0-2.4-.81,5.4,5.4,0,0,0-2.45.25C473.69,222.3,473.5,222.53,473.59,222.6Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M473.28,224.82a1,1,0,0,0,.85,1.1,1,1,0,0,0,1.12-.79,1,1,0,0,0-.85-1.1A1,1,0,0,0,473.28,224.82Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M460.33,223.4a1,1,0,0,0,.85,1.11,1,1,0,0,0,1.12-.79,1,1,0,0,0-.85-1.11A1,1,0,0,0,460.33,223.4Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M468,231.74a8.8,8.8,0,0,0-2.21-.64c-.35-.08-.68-.18-.71-.43a1.77,1.77,0,0,1,.35-1c.42-.82.86-1.68,1.33-2.58a49.16,49.16,0,0,0,3.09-6.8c-.15-.08-1.78,2.85-3.63,6.53L465,229.4a2,2,0,0,0-.33,1.37.91.91,0,0,0,.53.58,2.56,2.56,0,0,0,.59.15A9.06,9.06,0,0,0,468,231.74Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M475.26,216.55c.09.36,1.32.32,2.74.65s2.53.86,2.76.57-.07-.49-.5-.87a4.62,4.62,0,0,0-2-1,4.44,4.44,0,0,0-2.2,0C475.52,216.14,475.23,216.38,475.26,216.55Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M460.61,216.77c.11.3,1.33.21,2.74.42s2.56.6,2.76.34-.1-.4-.56-.7a5.33,5.33,0,0,0-4.18-.58C460.84,216.41,460.57,216.63,460.61,216.77Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#f4d3bd" },
              attrs: {
                d: "M488.32,228.27c.16-.05,6.39-1.19,5.55,5s-6.84,4-6.83,3.85S488.32,228.27,488.32,228.27Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#eaae94" },
              attrs: {
                d: "M489.24,235s.09.09.26.2a1.09,1.09,0,0,0,.8.13,2.68,2.68,0,0,0,1.55-2.21,3.45,3.45,0,0,0-.09-1.58,1.23,1.23,0,0,0-.69-.93.55.55,0,0,0-.67.21c-.11.16-.08.29-.12.29s-.12-.12,0-.37a.71.71,0,0,1,.29-.35.81.81,0,0,1,.62-.07,1.51,1.51,0,0,1,1,1.1,3.7,3.7,0,0,1,.14,1.77,2.81,2.81,0,0,1-1.93,2.44,1.16,1.16,0,0,1-1-.3C489.23,235.16,489.21,235,489.24,235Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M457.35,213.59a13.08,13.08,0,0,0,11.85-1.39c2.39-1.63,4.36-4.13,7.2-4.68a7.35,7.35,0,0,1,5.52,1.35,14.2,14.2,0,0,1,3.84,4.35c-2.22,4.4-1.59,9.64-.9,14.52a2.45,2.45,0,0,0,.57,1.5,2,2,0,0,0,2.51-.28,7.68,7.68,0,0,0,2.1-3.85,25.53,25.53,0,0,0,1-10,17.35,17.35,0,0,0-3.83-9.21,16.42,16.42,0,0,0-11-5.54,20.15,20.15,0,0,0-12.09,2.94,11.88,11.88,0,0,0-4.24,4",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M457.89,222.12c.58-2.48.85-5.61,1.43-8.09a15.1,15.1,0,0,0,11.14-2.77c1.64-1.27,3.06-3,5.05-3.56,2.57-.74,5.23.61,7.56,1.92l-.09-.11a11.35,11.35,0,0,1,3,3.1,35.26,35.26,0,0,0-1.13,15,2.57,2.57,0,0,0,1.17,1.85c1.27.77,2.22-.1,3.18-.69.7-.44,1.7-.72,2.3-.15a35,35,0,0,0,4.06-10,2.52,2.52,0,0,0,4.44,2.22,5.31,5.31,0,0,1-1.22-2.52,3.26,3.26,0,0,0,4.65-.37l-1.95-2,2.78.15a14.85,14.85,0,0,1-5.28-4.57,22.94,22.94,0,0,0-4.91-5c-2-1.28-4.64-1.8-6.68-.62.14-3.25-2.19-6.26-5.14-7.63a21.08,21.08,0,0,0-11.18-1.11c-2.46.52-5,2-8.21,4.31a29.94,29.94,0,0,0-4.26,3.68c-1.94,2.05-5.81,5.41-9.52,4.41l.33,3.82c2,1.91,6.13.23,6.13.23s-2.38,2.37-4.61,2.31l.6,3.28s3.37.59,4.85-2Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M485.76,213.22s-.29-.48-.9-1.29a11.42,11.42,0,0,0-3.1-2.86,11.76,11.76,0,0,0-2.72-1,5.87,5.87,0,0,0-3.25-.08c-2.26.68-3.91,3-6.33,4.43a13.93,13.93,0,0,1-7.52,2.23,17.15,17.15,0,0,1-1.78-.08,1.63,1.63,0,0,0-.74,0,7.72,7.72,0,0,0-.71.35,6.78,6.78,0,0,0-1.9,2.13,5.37,5.37,0,0,1-3.7,2.19,6.15,6.15,0,0,1-1.18,0,1.42,1.42,0,0,1-.4-.07c0-.05.57,0,1.55-.15a5.31,5.31,0,0,0,3.45-2.21,6.86,6.86,0,0,1,2-2.28,6.72,6.72,0,0,1,.76-.38,2.09,2.09,0,0,1,.92-.08,16.38,16.38,0,0,0,1.73.06,13.75,13.75,0,0,0,7.27-2.2,25.91,25.91,0,0,0,3.17-2.45,9.49,9.49,0,0,1,3.29-2,6.28,6.28,0,0,1,3.48.11,12,12,0,0,1,2.79,1.14,10.77,10.77,0,0,1,3.08,3.06,7.14,7.14,0,0,1,.6,1C485.73,213.08,485.78,213.21,485.76,213.22Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M495.47,218.63s0,.19-.17.51a4.63,4.63,0,0,1-.78,1.28,2.39,2.39,0,0,1-2.14.87,1.68,1.68,0,0,1-1.21-.86,2.5,2.5,0,0,1-.27-1.55l.46.12a2,2,0,0,1-1.74,1.17,2.44,2.44,0,0,1-1.82-.68,3.8,3.8,0,0,1-1-1.49,10,10,0,0,1-.41-2.87,14.18,14.18,0,0,0-.33-2.57,4.75,4.75,0,0,1,.67,2.55,10.43,10.43,0,0,0,.5,2.73,3.33,3.33,0,0,0,.86,1.29,2,2,0,0,0,1.47.55,1.53,1.53,0,0,0,1.32-.86l.83-2.07-.37,2.19a1.61,1.61,0,0,0,1.08,1.94,2.1,2.1,0,0,0,1.84-.67,6.06,6.06,0,0,0,.87-1.15C495.34,218.77,495.45,218.62,495.47,218.63Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M493.21,225.39a2.65,2.65,0,0,1-1.73.45,5.93,5.93,0,0,1-3.93-1.72,11.19,11.19,0,0,1-2.35-3.58,5.47,5.47,0,0,1-.58-1.66,7.38,7.38,0,0,1,.89,1.51,13.8,13.8,0,0,0,2.38,3.38,6.2,6.2,0,0,0,3.61,1.72A13.18,13.18,0,0,0,493.21,225.39Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M492.92,226.54a3.26,3.26,0,0,1-1.52.11,8.27,8.27,0,0,1-3.49-1.18,8.13,8.13,0,0,1-2.64-2.58,3,3,0,0,1-.59-1.41,10.14,10.14,0,0,1,.88,1.21,9.86,9.86,0,0,0,2.6,2.36,9.72,9.72,0,0,0,3.29,1.25A9.18,9.18,0,0,1,492.92,226.54Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M496.9,203.52a9.88,9.88,0,0,1-1.54-.21,10.3,10.3,0,0,0-3.65.22,10.43,10.43,0,0,0-3.34,1.51c-.77.54-1.2,1-1.26.91a3.55,3.55,0,0,1,1.05-1.19,8.85,8.85,0,0,1,3.43-1.71,8.57,8.57,0,0,1,3.82-.08A3.48,3.48,0,0,1,496.9,203.52Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M488.54,198.17a7.79,7.79,0,0,1-1.28,8.19,19.1,19.1,0,0,0,1.33-4A18.49,18.49,0,0,0,488.54,198.17Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M452.62,209.35a4.22,4.22,0,0,0,1.29.74,7.12,7.12,0,0,0,4-.47c.82-.27,1.71-.63,2.68-1a17.13,17.13,0,0,0,2.93-1.38c2-1.14,4-2.77,6.47-3.88a11.3,11.3,0,0,1,3.84-1,7.08,7.08,0,0,1,3.63.75c2.11,1.1,3.6,2.62,5.2,3.25a5.17,5.17,0,0,0,3.93.18,4.22,4.22,0,0,0,1.19-.87s-.05.12-.21.34a2.9,2.9,0,0,1-.9.71,5.28,5.28,0,0,1-4.14,0c-1.71-.64-3.25-2.16-5.27-3.17a6.68,6.68,0,0,0-3.4-.68,11.21,11.21,0,0,0-3.67,1c-2.41,1.07-4.41,2.68-6.45,3.82a16.8,16.8,0,0,1-3,1.38c-1,.34-1.86.69-2.7.94a7,7,0,0,1-4.15.32,2.71,2.71,0,0,1-1-.61A1.51,1.51,0,0,1,452.62,209.35Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M487.46,205.84a30.62,30.62,0,0,0-2.37-2.4,14.13,14.13,0,0,0-7.32-3.19,24.53,24.53,0,0,0-4.47.05,25.13,25.13,0,0,1-3.72.08,8.15,8.15,0,0,1-2.46-.54,2.49,2.49,0,0,1-.81-.44,12.36,12.36,0,0,0,3.29.63,29.35,29.35,0,0,0,3.67-.18,22,22,0,0,1,4.56-.09,13.64,13.64,0,0,1,7.49,3.42,12.23,12.23,0,0,1,1.67,1.87A3,3,0,0,1,487.46,205.84Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M468.71,236.22c0-.17,1.22-.31,2.26-1.28a6.9,6.9,0,0,0,1.13-1.44c.26-.41.45-.67.51-.63s0,.32-.2.79a4.39,4.39,0,0,1-1.1,1.64,3.73,3.73,0,0,1-1.77.92C469,236.32,468.72,236.28,468.71,236.22Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M629.62,408.06,646,398.78s14.93,13.53,18.13,12.11L647.27,378.5l-26.11,13.41Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M639.38,389.5a1.5,1.5,0,0,1,1-1.7,1.44,1.44,0,0,1,1.7,1A1.56,1.56,0,0,1,641,390.6a1.48,1.48,0,0,1-1.64-1.24",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M647.27,378.5,644.69,380l17.12,30.52s1.38,1.11,2.31.42Z",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M646.1,399.39c.16-.08-.18-.91-.08-1.95s.55-1.81.42-1.92-.93.6-1,1.86S646,399.5,646.1,399.39Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M649.28,402.06c.17,0,.08-.87.47-1.75s1-1.41.93-1.56-1,.21-1.5,1.32S649.12,402.14,649.28,402.06Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M654.34,401.88c0-.17-.94,0-1.6.88s-.61,1.78-.44,1.78.39-.7.94-1.4S654.41,402,654.34,401.88Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M642.14,401.1c.11-.15-.49-.76-.89-1.68s-.49-1.77-.67-1.78-.39.95.1,2S642.05,401.24,642.14,401.1Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("polygon", {
                attrs: {
                  points:
                    "632.86 406.22 635.78 404.57 627.36 388.73 624.67 390.1 632.86 406.22",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M638.8,402.91c.07,0-.16-.71-.85-1.62a8.55,8.55,0,0,0-1.41-1.43,8.39,8.39,0,0,0-1-.67,2.05,2.05,0,0,0-.63-.29.78.78,0,0,0-.88.36,1,1,0,0,0,0,.88,2.13,2.13,0,0,0,.4.57,6.77,6.77,0,0,0,.87.83,6.29,6.29,0,0,0,1.76,1,3,3,0,0,0,1.81.27c0-.1-.67-.21-1.64-.67a7.77,7.77,0,0,1-1.57-1,7.11,7.11,0,0,1-.77-.78c-.27-.29-.42-.58-.32-.75s.07-.08.2-.06a2.07,2.07,0,0,1,.47.21,9.52,9.52,0,0,1,.94.61,10.71,10.71,0,0,1,1.41,1.25C638.35,402.37,638.71,403,638.8,402.91Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M638.92,402.58a2.87,2.87,0,0,0-1.79.39,6.1,6.1,0,0,0-1.66,1.14,6,6,0,0,0-.77.93,1.51,1.51,0,0,0-.28,1.46.84.84,0,0,0,.8.44,2.15,2.15,0,0,0,.68-.14,4.19,4.19,0,0,0,2.41-2.13,2.37,2.37,0,0,0,.3-1.81c-.11,0-.13.69-.68,1.6a4.28,4.28,0,0,1-1.26,1.3,3.64,3.64,0,0,1-.95.46c-.37.13-.72.15-.75,0a1,1,0,0,1,.24-.85,5.71,5.71,0,0,1,.67-.85,6.57,6.57,0,0,1,1.45-1.16C638.26,402.82,638.94,402.68,638.92,402.58Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M583,433.57l18.57-3.05s9.34,17.86,12.82,17.63l-4.61-36.22-29.13,3.56Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M598.54,419.53a1.47,1.47,0,0,1,1.55-1.24,1.43,1.43,0,0,1,1.24,1.53,1.56,1.56,0,0,1-1.65,1.3,1.49,1.49,0,0,1-1.12-1.74",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M609.74,411.93l-2.92.47L612.33,447s.91,1.52,2,1.2Z",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M601.42,431.14c.18,0,.15-.93.6-1.87s1.15-1.5,1.06-1.66-1.08.24-1.62,1.4S601.27,431.19,601.42,431.14Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M603.48,434.73c.18,0,.38-.78,1-1.48s1.44-1,1.41-1.14-1-.16-1.86.72S603.31,434.75,603.48,434.73Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M608.3,436.31c0-.16-.9-.3-1.81.28s-1.19,1.45-1,1.51.61-.52,1.36-1S608.31,436.49,608.3,436.31Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M597.11,431.36c.16-.09-.19-.87-.25-1.88s.16-1.83,0-1.9-.7.75-.61,1.94S597,431.46,597.11,431.36Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("polygon", {
                attrs: {
                  points:
                    "586.64 432.96 589.95 432.43 587.52 414.65 584.52 415 586.64 432.96",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M593.36,431.91a3.55,3.55,0,0,0-.24-1.82,8.62,8.62,0,0,0-.83-1.82,9.53,9.53,0,0,0-.68-1,2.33,2.33,0,0,0-.5-.49.77.77,0,0,0-1,0,1,1,0,0,0-.31.83,2.7,2.7,0,0,0,.18.67,6.78,6.78,0,0,0,.53,1.08,6.85,6.85,0,0,0,1.31,1.53,3.16,3.16,0,0,0,1.61.88c0-.09-.55-.43-1.31-1.2a7.49,7.49,0,0,1-1.11-1.5,7.06,7.06,0,0,1-.46-1c-.15-.37-.19-.7,0-.82s.08-.05.21,0a2.13,2.13,0,0,1,.36.37,9,9,0,0,1,.67.89,10.16,10.16,0,0,1,.89,1.66A13.45,13.45,0,0,0,593.36,431.91Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M593.58,431.64a3,3,0,0,0-1.81-.25,6,6,0,0,0-2,.5,6.48,6.48,0,0,0-1,.6,1.52,1.52,0,0,0-.77,1.27.85.85,0,0,0,.6.7,2.61,2.61,0,0,0,.69.1,4.41,4.41,0,0,0,1.23-.15,4.26,4.26,0,0,0,1.77-1c.85-.83,1-1.59.9-1.6s-.35.61-1.19,1.27a4.14,4.14,0,0,1-1.63.79,3.64,3.64,0,0,1-1,.1c-.39,0-.72-.11-.7-.26s.22-.52.51-.71a5.41,5.41,0,0,1,.93-.57,6.87,6.87,0,0,1,1.76-.59C592.88,431.64,593.57,431.74,593.58,431.64Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M506.73,333.38c-.58.23,8.79,9.25,10.3,15.36,1.81,7.37,6.75,15.8,6.75,15.8L558.4,397.7l65.2-9.54,11,20.09L556,429.56a14.3,14.3,0,0,1-12.27-2.32L493,389.54l-29.08-36Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M459.1,356.28l4.2,15.44c11.78,24.34,34.22,70.43,40.16,73.4,8.26,4.13,84.23-11,84.23-11L586,413.18l-63.75,5.51-42.64-74.91Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M629.57,409.62s-.14.06-.43.15l-1.28.39-4.93,1.44-18.18,5.2-27,7.66L561.86,429c-1.39.38-2.76.8-4.19,1.16a19.83,19.83,0,0,1-13.26-1.45,21.78,21.78,0,0,1-3.87-2.44L537,423.49q-3.5-2.76-6.83-5.5c-4.42-3.66-8.65-7.24-12.59-10.75-7.92-7-14.8-13.56-20.4-19.25s-9.88-10.54-12.68-14l-1.85-2.32c-.49-.7-.92-1.29-1.26-1.77l-.78-1.09c-.17-.25-.24-.38-.24-.38s.1.11.29.35.46.61.81,1.06.79,1.06,1.3,1.74l1.88,2.29c2.84,3.44,7.15,8.25,12.78,13.9S510,399.94,517.88,406.9c4,3.49,8.17,7.07,12.6,10.72,2.21,1.83,4.49,3.65,6.82,5.49l3.54,2.73a20.22,20.22,0,0,0,3.79,2.4,19.4,19.4,0,0,0,12.92,1.42c1.4-.35,2.8-.78,4.18-1.16L577.67,424l27-7.55,18.21-5.06,5-1.35,1.3-.34Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M480.39,357.25c.05.06-.42.28-.51.95a1.3,1.3,0,0,0,.29,1,1.15,1.15,0,0,0,1.88-.82,1.28,1.28,0,0,0-.55-.91c-.56-.39-1-.2-1.05-.28s.08-.11.3-.17a1.36,1.36,0,0,1,.93.16,1.54,1.54,0,0,1,.82,1.16,1.57,1.57,0,0,1-2.67,1.16,1.57,1.57,0,0,1-.29-1.4,1.37,1.37,0,0,1,.52-.78C480.25,357.23,480.38,357.22,480.39,357.25Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M513.6,348.23a3,3,0,0,1-.57.61,14.54,14.54,0,0,1-1.83,1.37,15.79,15.79,0,0,1-7.15,2.36,15.55,15.55,0,0,1-7.41-1.24,12.22,12.22,0,0,1-2-1.07,2.74,2.74,0,0,1-.67-.52c.05-.08,1,.59,2.82,1.27a16.53,16.53,0,0,0,14.25-1.1C512.67,349,513.54,348.16,513.6,348.23Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M572.44,292.87s-45.33-17-48.08-19.46-14.72-16-19.7-17.75c0,0-8.08-2.12-13.58-1.81l-5.81.69v-4.41l-23.87.39-.66,5.88-4.07,1.54s-9.56,20-9.56,29.12-4.72,62.51,4.58,72.77c1.43,1.57,7,.56,7,.56s26.84-2.86,53.52-21.52l-9.77-11.29S499,304,503,287.06c.09-.37.17-.74.26-1.1,11.55,5.7,29.33,14,34.55,14,0,0,29.24,9.87,34.64,3.55C573.06,302.8,572.44,292.87,572.44,292.87Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M461.31,257.06s-9.35.37-15.44,7.94-16,39.86-16,39.86l-13.64-14-13,7.21s18.38,25.62,22,28.59a19.4,19.4,0,0,0,6.64,2.83c8.73.76,14.63-8.13,16.42-15.46l6-30.13",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#f4d3bd" },
              attrs: {
                d: "M407.18,295.85l-14-8.31s-4.23-10-2.61-10.47,3,5.57,3,5.57l2,1s-2.73-5.08-1.78-5.56,2.28,1,3,2.17c.56.88,2.37,4.57,2.37,4.57l.94-1.3-.74-3.41s-1-2.28.11-2.3,1.11-.1,1.94,1.79,1.4,3.3,1.4,3.3,4.08,2.42,4.95,2.15a5.11,5.11,0,0,1,4,.31s3.87,2,2.94,6.15",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M501.88,258.37c.07,0-.3,1-.75,2.52a28,28,0,0,0-.69,12.47c.26,1.59.53,2.56.45,2.59a9.8,9.8,0,0,1-.8-2.52,23.79,23.79,0,0,1-.5-6.37,23.52,23.52,0,0,1,1.21-6.27A9.62,9.62,0,0,1,501.88,258.37Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M464.41,257.13a9.38,9.38,0,0,1-.25-3.4,9.26,9.26,0,0,1,.52-3.37,16.54,16.54,0,0,1,0,3.39A16.77,16.77,0,0,1,464.41,257.13Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M470,258a14.71,14.71,0,0,1,.08-3.82,15.59,15.59,0,0,1,.58-3.77,14.48,14.48,0,0,1-.09,3.82A15.31,15.31,0,0,1,470,258Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M477.44,257.77c-.14,0,.21-1.67.34-3.7s0-3.69.18-3.71a12.36,12.36,0,0,1-.52,7.41Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M482.9,256.4c-.14,0,.08-1.41.05-3.11s-.29-3.07-.15-3.1a5.91,5.91,0,0,1,.64,3.09A6.21,6.21,0,0,1,482.9,256.4Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M447.6,269.28c.13,0,0,13.06-.3,29.16s-.66,29.16-.8,29.16,0-13.06.31-29.17S447.46,269.28,447.6,269.28Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M446.7,300a12.4,12.4,0,0,1-2-1.11,27.57,27.57,0,0,0-4.78-2.44,12.15,12.15,0,0,0-5.22-.76c-1.36.12-2.16.47-2.19.39a4.8,4.8,0,0,1,2.15-.74,11.16,11.16,0,0,1,5.43.65,21.47,21.47,0,0,1,4.8,2.61A8.87,8.87,0,0,1,446.7,300Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M501.36,290.83a3.42,3.42,0,0,1-.47.3l-1.38.81c-.6.35-1.35.76-2.24,1.19s-1.88,1-3,1.44-2.38,1.05-3.76,1.55-2.85,1-4.41,1.52a80.06,80.06,0,0,1-10.42,2.41,81.19,81.19,0,0,1-10.66.88c-1.65,0-3.21,0-4.67-.11s-2.82-.2-4.06-.33-2.34-.31-3.32-.46-1.81-.3-2.49-.45l-1.56-.35a2.09,2.09,0,0,1-.54-.15,2.13,2.13,0,0,1,.56.05l1.58.26c.68.12,1.51.26,2.49.37s2.08.3,3.32.38,2.58.23,4,.27,3,.06,4.64.06a77.58,77.58,0,0,0,21-3.26c1.56-.5,3.05-.94,4.4-1.47s2.63-1,3.77-1.49,2.15-1,3-1.37,1.65-.79,2.27-1.11l1.42-.72A2,2,0,0,1,501.36,290.83Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M527.67,276.35c.05.07-.8.63-2,1.7a25.89,25.89,0,0,0-4.26,4.68,26.69,26.69,0,0,0-3,5.6c-.58,1.52-.83,2.51-.91,2.49a2.22,2.22,0,0,1,.08-.71,15.71,15.71,0,0,1,.5-1.9,22,22,0,0,1,7.34-10.43,12.91,12.91,0,0,1,1.61-1.11C527.43,276.43,527.65,276.32,527.67,276.35Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M532.74,277.91c0,.08-1,.54-2.47,1.51a21.91,21.91,0,0,0-8.53,10.65c-.63,1.67-.85,2.76-.93,2.74s0-.28.07-.78a14.31,14.31,0,0,1,.53-2.07,19.91,19.91,0,0,1,8.67-10.84,14.66,14.66,0,0,1,1.91-1A2.46,2.46,0,0,1,532.74,277.91Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M501.72,286.72a2.27,2.27,0,0,1-.51.24l-1.47.6c-.64.26-1.43.57-2.36.88s-2,.72-3.17,1.07c-2.36.76-5.21,1.53-8.4,2.26s-6.75,1.33-10.5,1.78-7.36.62-10.63.68-6.23-.08-8.7-.28c-1.23-.07-2.34-.21-3.32-.31s-1.82-.21-2.5-.32l-1.58-.24A2,2,0,0,1,448,293a2.15,2.15,0,0,1,.56,0l1.58.15c.69.08,1.53.17,2.51.24s2.08.19,3.32.23c2.46.16,5.4.22,8.66.17s6.84-.29,10.58-.71,7.27-1.06,10.46-1.73,6-1.44,8.4-2.15c1.19-.33,2.24-.7,3.18-1s1.73-.57,2.38-.8l1.51-.51A2.21,2.21,0,0,1,501.72,286.72Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fafafa" },
              attrs: {
                d: "M447.2,292.39a36.08,36.08,0,0,0-5.33-2.89A36.67,36.67,0,0,0,436,288a5.05,5.05,0,0,1,1.81,0,16.66,16.66,0,0,1,4.25,1,16.29,16.29,0,0,1,3.82,2.12A4.76,4.76,0,0,1,447.2,292.39Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#f4d3bd" },
              attrs: {
                d: "M587.36,289.11l-9.9,3.32-5,.44v10.66l5.14,1.75,13.79-.75s7.9,4,9.91,3.76,2-.86,2-.86l-9.77-5.91a5.6,5.6,0,0,1,3.76.5c2.25,1,6.14,3.26,7.52,2.64s1.13-1.13,1.13-1.13l-7.65-4.82s6.55,2.94,7.67,2.44a2,2,0,0,0,1.23-1.48l-4.26-3.79-6.4-5Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#eaae94" },
              attrs: {
                d: "M581.86,293.5a5.11,5.11,0,0,1,1.08.45c.67.31,1.64.78,2.83,1.38s2.6,1.31,4.15,2.19a3.51,3.51,0,0,1,1.11.88,2.63,2.63,0,0,1,.59,1.41,2.44,2.44,0,0,1-.34,1.56,2.29,2.29,0,0,1-1.38.89,4.63,4.63,0,0,1-2.19,0,15.81,15.81,0,0,1-1.91-.69c-1.2-.5-2.3-1-3.3-1.53l.26-.06a5.9,5.9,0,0,1-2.3,1.83,5.18,5.18,0,0,1-2.22.42,5.27,5.27,0,0,1-1.42-.24c-.31-.1-.47-.19-.46-.21a10.29,10.29,0,0,0,1.89.21,5.32,5.32,0,0,0,2.07-.49,5.77,5.77,0,0,0,2.11-1.77l.1-.14.15.08c1,.47,2.11,1,3.3,1.47a15.53,15.53,0,0,0,1.85.65,4.38,4.38,0,0,0,1.94,0,1.88,1.88,0,0,0,1.1-.69,2,2,0,0,0,.27-1.24,2.52,2.52,0,0,0-1.46-1.95c-1.5-.89-2.91-1.63-4.07-2.27l-2.76-1.52A5,5,0,0,1,581.86,293.5Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#eaae94" },
              attrs: {
                d: "M598.74,298.74a19.23,19.23,0,0,1-4.38-2.72,9.66,9.66,0,0,1-.89-.77l.3,0A6.21,6.21,0,0,1,591,296a2.18,2.18,0,0,1-1.14-.11c0-.07.42-.08,1.11-.2a7.45,7.45,0,0,0,2.58-.85l.17-.11.14.14c.25.24.55.48.85.73A45.21,45.21,0,0,1,598.74,298.74Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M615.63,375.37a20.49,20.49,0,0,1-5.9-.85c-19-5.69-18-19.4-17.23-30.42.74-10.27.77-16.73-7.7-19.91-11.21-4.2-15.82-.12-22.81,6.06-8.32,7.36-18.66,16.52-43.76,12.8-13-1.93-34-8.8-59.71-30.58a205.29,205.29,0,0,1-25.33-25.4l3.88-3.12c.39.48,38.78,47.79,81.89,54.17,22.8,3.38,31.79-4.58,39.73-11.6,7.12-6.3,13.84-12.25,27.86-7,12.38,4.65,11.61,15.42,10.92,24.93-.8,11.13-1.49,20.74,13.69,25.29,17.1,5.12,39.59-22,53-38.16,4.8-5.77,8.13-9.95,10.79-12.12l3.76,3c-1.66,2-6.28,7-10.72,12.31C654.33,351.26,634.31,375.37,615.63,375.37Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M545.14,336s-.2.19-.6.44a15.3,15.3,0,0,1-1.75,1,26.83,26.83,0,0,1-6.29,2.16,26.41,26.41,0,0,1-6.62.51,19.27,19.27,0,0,1-2-.21,2.52,2.52,0,0,1-.72-.17c0-.08,1.06,0,2.74,0a31.35,31.35,0,0,0,12.75-2.64C544.18,336.39,545.1,335.88,545.14,336Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M574.38,320.91a11.06,11.06,0,0,1-1.63-.06,11.2,11.2,0,0,0-7.19,2.48,11.08,11.08,0,0,1-1.25,1.05,3.67,3.67,0,0,1,1-1.31,9.55,9.55,0,0,1,7.44-2.56A3.63,3.63,0,0,1,574.38,320.91Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M606.23,368.14a6.58,6.58,0,0,1-2.25-.69,14.52,14.52,0,0,1-7.56-8.18,6.66,6.66,0,0,1-.51-2.29c.08,0,.28.85.84,2.16a16.49,16.49,0,0,0,3,4.64,16.2,16.2,0,0,0,4.39,3.35C605.4,367.79,606.26,368.06,606.23,368.14Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M675.86,321.27a61,61,0,0,1-7.88,7.86,64.53,64.53,0,0,1,7.88-7.86Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M437.6,288.17h0a5.75,5.75,0,0,1-8.12.39l-8.78-8,7.74-8.51,8.77,8A5.75,5.75,0,0,1,437.6,288.17Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M437.6,288.17a14.17,14.17,0,0,0,.81-1.34,5.67,5.67,0,0,0,.27-4.17,5.6,5.6,0,0,0-1.59-2.47l-2.65-2.37c-1.87-1.7-3.95-3.57-6.16-5.56l.34,0-3.87,4.26-3.87,4.25v-.34L427,286l2.62,2.41a5.63,5.63,0,0,0,2.6,1.35,5.69,5.69,0,0,0,4.13-.67,13.42,13.42,0,0,0,1.26-.94,4.35,4.35,0,0,1-1.17,1.08,5.72,5.72,0,0,1-4.28.83,5.91,5.91,0,0,1-2.79-1.38c-.83-.74-1.71-1.54-2.65-2.37l-6.17-5.57-.18-.16.17-.18,3.86-4.26,3.87-4.24.16-.18.18.16,6.12,5.61,2.62,2.41a5.84,5.84,0,0,1,1.64,2.65,5.73,5.73,0,0,1-.42,4.33A4.18,4.18,0,0,1,437.6,288.17Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M393.24,240.13,378.1,227.61a2.79,2.79,0,0,1-.37-3.93h0a2.79,2.79,0,0,1,3.93-.37l15.13,12.52Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M379,258.42,363.88,245.9a2.79,2.79,0,0,1-.38-3.93h0a2.78,2.78,0,0,1,3.93-.37l15.14,12.51Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M393.24,240.13,378.1,227.61a2.79,2.79,0,0,1-.37-3.93h0a2.79,2.79,0,0,1,3.93-.37l15.13,12.52Z",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M379,258.42,363.88,245.9a2.79,2.79,0,0,1-.38-3.93h0a2.78,2.78,0,0,1,3.93-.37l15.14,12.51Z",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M393.24,240.13a3,3,0,0,1,.23-.33l.68-.88,2.53-3.19,0,.21-7.11-5.78-4.45-3.66-2.39-2-1.23-1a2.46,2.46,0,0,0-1.33-.58,2.53,2.53,0,0,0-2.24.86,2.57,2.57,0,0,0-.54,2.28,2.36,2.36,0,0,0,.54,1,8.11,8.11,0,0,0,.91.82l1.88,1.56,6.48,5.45,4.39,3.74,1.2,1a4.06,4.06,0,0,1,.43.4,2.64,2.64,0,0,1-.42-.3l-1.2-1c-1.11-.89-2.63-2.1-4.47-3.59l-6.66-5.41-1.92-1.57a11,11,0,0,1-1-.87,3,3,0,0,1,2.67-5,3.12,3.12,0,0,1,1.59.69l1.22,1,2.38,2,4.43,3.69,7,5.89.12.1-.1.11-2.71,3.16-.72.8A1.77,1.77,0,0,1,393.24,240.13Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M379,258.42a1.91,1.91,0,0,1,.24-.33c.18-.24.4-.53.68-.88l2.53-3.19,0,.21-7.11-5.79-4.45-3.65-2.39-2-1.23-1a2.62,2.62,0,0,0-1.34-.58,2.56,2.56,0,0,0-2.24.87,2.53,2.53,0,0,0-.53,2.28,2.4,2.4,0,0,0,.53,1,8.18,8.18,0,0,0,.92.81l1.87,1.57,6.49,5.45,4.39,3.74,1.2,1a2.63,2.63,0,0,1,.42.4s-.14-.09-.41-.3l-1.21-.95-4.47-3.59-6.65-5.41-1.92-1.58a8.57,8.57,0,0,1-1-.87,2.89,2.89,0,0,1-.64-1.24,3,3,0,0,1,.63-2.71,3.06,3.06,0,0,1,2.67-1,3.13,3.13,0,0,1,1.59.69c.41.35.82.68,1.23,1l2.38,2,4.43,3.68,7,5.9.11.1-.09.11c-1.14,1.31-2.05,2.37-2.72,3.15l-.71.81C379.1,258.34,379,258.43,379,258.42Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M427.64,256l.4-.48a5.35,5.35,0,0,0-7.44-7.61l-22.16-18.46-25.35,30.33,22.68,18a5.35,5.35,0,0,0,1.09,7h0a5.34,5.34,0,0,0,7.44-.62,15.41,15.41,0,0,0,21.31-2.67l3.64-4.54A15.42,15.42,0,0,0,427.64,256Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M427.64,256l.19.17.53.51a7.36,7.36,0,0,1,.81.88l.49.6.5.73a14.62,14.62,0,0,1,2,4.17,15.41,15.41,0,0,1-2.23,13.33c-.81,1.15-1.75,2.24-2.67,3.43a29.26,29.26,0,0,1-3.07,3.53,15.39,15.39,0,0,1-9.26,4.1,15.65,15.65,0,0,1-10.73-3.06l.31,0a5.64,5.64,0,0,1-3.65,1.89,5.54,5.54,0,0,1-4.11-1.24l0,0,0,0a5.6,5.6,0,0,1-1.14-7.32l.05.31L378.07,264l-5.13-4.08-.2-.15.16-.2c9.24-11,17.87-21.34,25.38-30.3l.14-.17.16.14,22.11,18.51h-.17a5.45,5.45,0,0,1,8.82,4.35,5.28,5.28,0,0,1-1,3,6.67,6.67,0,0,1-.52.63l-.19.21s.24-.3.65-.88a5.41,5.41,0,0,0,.9-3,5.28,5.28,0,0,0-2.23-4.29,5.13,5.13,0,0,0-3-1,5.23,5.23,0,0,0-3.31,1.13l-.09.07-.09-.07-22.2-18.4.3,0L373.28,259.9l0-.35,5.12,4.08,17.55,14,.17.13-.12.18a5.13,5.13,0,0,0,1,6.71h0a5.12,5.12,0,0,0,7.13-.59l.14-.15.16.12A15.24,15.24,0,0,0,423.9,283a29.82,29.82,0,0,0,3-3.48c.93-1.18,1.89-2.28,2.69-3.4a15.34,15.34,0,0,0,.42-17.23c-.17-.26-.33-.51-.49-.74l-.48-.6a9.2,9.2,0,0,0-.78-.92l-.51-.53Q427.63,256,427.64,256Z",
              },
            }),
            _c("rect", {
              staticStyle: { fill: "#e0e0e0" },
              attrs: {
                x: "383.04",
                y: "249.66",
                width: "20.29",
                height: "3.97",
                transform: "translate(250.56 -192.85) rotate(39.59)",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M427.64,256a8.54,8.54,0,0,1-.84,1.18c-.56.75-1.39,1.81-2.43,3.11-2.07,2.61-5,6.16-8.27,10.05s-6.18,7.34-8.39,9.95l-2.54,3a8,8,0,0,1-1,1.06,8.54,8.54,0,0,1,.84-1.18c.56-.75,1.39-1.81,2.43-3.11,2.07-2.61,5-6.16,8.27-10l8.39-10,2.55-3A7.67,7.67,0,0,1,427.64,256Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M420.6,247.87a8.39,8.39,0,0,1-.9,1.24c-.61.78-1.51,1.9-2.62,3.27-2.24,2.74-5.38,6.48-8.88,10.58s-6.64,7.74-9,10.49c-1.12,1.28-2,2.35-2.74,3.16a9.44,9.44,0,0,1-1.06,1.12,10.43,10.43,0,0,1,.91-1.24c.61-.78,1.5-1.9,2.62-3.27,2.24-2.74,5.38-6.48,8.88-10.58l9-10.48,2.74-3.17A10,10,0,0,1,420.6,247.87Z",
              },
            }),
          ]),
          _c("g", { attrs: { id: "freepik--character-1--inject-17" } }, [
            _c("path", {
              staticStyle: { fill: "#b78876" },
              attrs: {
                d: "M321,167.47c-.14.33-11.17,8.71-11.17,8.71l-.21,7.12,5.7.8,8.1-4.6,5.49-.28s3.35,1.77,4,2a1.94,1.94,0,0,0,2.25-.93l-5-3.64s5.54,3.09,6.07,2.68a1.72,1.72,0,0,0-.53-2.67s1.72.91,2.81.18.7-1.6.7-1.6l-7.32-6.57a5,5,0,0,0-3.41-1.28Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M321.88,170.56a4.81,4.81,0,0,1-.08.85c-.1.62-.22,1.4-.37,2.34l-.14-.17,4.21-.28a5.42,5.42,0,0,1,2.67.36,2.55,2.55,0,0,1,1.1,1.08,1.82,1.82,0,0,1,.07,1.63l0,.06-.06,0c-1.33.79-3,.61-4.45.71a5.17,5.17,0,0,0-3.1,1.13,20.47,20.47,0,0,0-2.31,2.24,10.36,10.36,0,0,1-2.3,1.94,5.15,5.15,0,0,1-2.54.71,10,10,0,0,1-3.68-.77,6.07,6.07,0,0,1-1.24-.57,8.36,8.36,0,0,1,1.31.38,10.61,10.61,0,0,0,3.6.61,4.91,4.91,0,0,0,2.34-.71,10.28,10.28,0,0,0,2.18-1.9,20.49,20.49,0,0,1,2.34-2.31,5.65,5.65,0,0,1,3.37-1.24c1.55-.09,3.09.05,4.24-.63l-.1.1a1.73,1.73,0,0,0-.94-2.1,4.92,4.92,0,0,0-2.45-.35l-4.22.16h-.18l0-.17c.2-.93.37-1.7.5-2.32A3.16,3.16,0,0,1,321.88,170.56Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M336.05,176.8a5,5,0,0,1-1.88-1.11c-1.08-.83-2.26-2.28-3.87-3.45a2.73,2.73,0,0,0-1.24-.52c-.44-.05-.91,0-1.35,0h-2.35a9.82,9.82,0,0,1-2.16-.14,8.9,8.9,0,0,1,2.16-.21l2.34-.09a11,11,0,0,1,1.41,0,3,3,0,0,1,1.47.6,40.23,40.23,0,0,1,3.81,3.59C335.36,176.33,336.09,176.73,336.05,176.8Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M336.56,388.47,336.29,406s17.69,6.15,17.95,9.39l-33.93.69-.7-27.27Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M325.76,404.71a1.38,1.38,0,0,0-.92,1.6,1.33,1.33,0,0,0,1.58.93,1.46,1.46,0,0,0,1-1.7,1.39,1.39,0,0,0-1.75-.79",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: { d: "M320.31,416l0-2.75,32.53.37s1.52.62,1.37,1.69Z" },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M336.83,405.78c0,.16-.82.26-1.63.81s-1.22,1.25-1.38,1.19.08-1,1.06-1.67S336.87,405.63,336.83,405.78Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M340.42,407.18c.05.16-.67.45-1.22,1.17s-.69,1.45-.86,1.45-.29-.94.41-1.81S340.42,407,340.42,407.18Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M342.53,411.4c-.15,0-.39-.79,0-1.7s1.18-1.3,1.25-1.16-.4.63-.72,1.39S342.7,411.38,342.53,411.4Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M336.45,401.79c-.06.15-.83-.07-1.76,0s-1.66.39-1.75.25.6-.75,1.7-.83S336.53,401.65,336.45,401.79Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("polygon", {
                attrs: {
                  points:
                    "336.5 391.93 336.46 395.05 319.77 395.24 319.69 392.43 336.5 391.93",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M336.44,398.26a3.31,3.31,0,0,1-1.7,0,7.84,7.84,0,0,1-1.79-.51,8.25,8.25,0,0,1-1-.5,1.72,1.72,0,0,1-.52-.39.73.73,0,0,1-.1-.88.93.93,0,0,1,.72-.39,2.08,2.08,0,0,1,.64.08,5.48,5.48,0,0,1,1.06.34,6,6,0,0,1,1.59,1c.8.72,1.09,1.33,1,1.36s-.47-.45-1.28-1a6.69,6.69,0,0,0-1.54-.82,5.61,5.61,0,0,0-1-.28c-.36-.09-.66-.08-.75.08s0,.08,0,.19a2.2,2.2,0,0,0,.38.28c.31.19.61.35.91.5a9.06,9.06,0,0,0,1.66.59A10.92,10.92,0,0,1,336.44,398.26Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M336.23,398.5a2.71,2.71,0,0,1-.48-1.64,5.49,5.49,0,0,1,.19-1.86,5.66,5.66,0,0,1,.42-1,1.38,1.38,0,0,1,1.06-.88.78.78,0,0,1,.72.46,2,2,0,0,1,.19.62,3.65,3.65,0,0,1,0,1.14,3.8,3.8,0,0,1-.69,1.77c-.64.9-1.33,1.11-1.34,1.05s.51-.41,1-1.27a4,4,0,0,0,.51-1.61,3.67,3.67,0,0,0-.06-1c-.06-.36-.2-.64-.33-.61s-.45.28-.59.57a6.68,6.68,0,0,0-.4.93,6,6,0,0,0-.29,1.71C336.13,397.85,336.32,398.47,336.23,398.5Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M274.8,289.57c.83,1.25,37.67,39.47,37.67,39.47s-4.58,17.61-2.93,32.92,6.62,39.71,6.62,39.71l26.71-2.13-7.27-83L306.44,278Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M345.79,369.13l3.28,17.19s18.57,2.44,19.49,5.56l-33.1,7.55-6.2-26.57Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M338.51,387.22a1.38,1.38,0,0,0-.58,1.76,1.33,1.33,0,0,0,1.73.59,1.46,1.46,0,0,0,.61-1.86,1.37,1.37,0,0,0-1.87-.42",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.6000000000000001" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d: "M335.46,399.43l-.52-2.7,31.93-6.23s1.62.3,1.69,1.38Z",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M349.57,386c0,.16-.76.42-1.44,1.12s-.94,1.48-1.1,1.45-.14-1,.69-1.86S349.57,385.87,349.57,386Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M353.37,386.67c.07.15-.57.59-1,1.39s-.39,1.57-.55,1.6-.47-.86,0-1.86S353.33,386.52,353.37,386.67Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M356.29,390.38c-.14.07-.55-.69-.34-1.67s.89-1.5,1-1.39-.26.7-.42,1.51S356.44,390.32,356.29,390.38Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M348.39,382.2c0,.16-.83.1-1.73.38s-1.54.72-1.66.6.44-.86,1.5-1.16S348.44,382.05,348.39,382.2Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("polygon", {
                attrs: {
                  points:
                    "346.44 372.54 347.03 375.6 330.73 379.17 330.08 376.43 346.44 372.54",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M347.66,378.74a3.16,3.16,0,0,1-1.66.37,8.22,8.22,0,0,1-2.92-.42,2.21,2.21,0,0,1-.59-.28.71.71,0,0,1-.27-.84.9.9,0,0,1,.62-.53,2.21,2.21,0,0,1,.65-.06,6.62,6.62,0,0,1,1.11.12,5.93,5.93,0,0,1,1.75.65c.93.54,1.33,1.08,1.29,1.13s-.55-.35-1.47-.76a6.74,6.74,0,0,0-1.67-.5,5.57,5.57,0,0,0-1-.07c-.37,0-.67.05-.72.23s0,.09.08.17a1.57,1.57,0,0,0,.43.2,6.91,6.91,0,0,0,1,.3,8.66,8.66,0,0,0,1.74.25A11.44,11.44,0,0,1,347.66,378.74Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M347.51,379c-.06,0-.51-.47-.81-1.5a5.88,5.88,0,0,1-.19-1.87,5.69,5.69,0,0,1,.2-1.1,1.41,1.41,0,0,1,.86-1.08.8.8,0,0,1,.8.31,2.15,2.15,0,0,1,.31.57,3.8,3.8,0,0,1,.26,1.11,4,4,0,0,1-.32,1.88c-.45,1-1.08,1.35-1.11,1.3a13.72,13.72,0,0,0,.73-1.45,4.05,4.05,0,0,0,.17-1.68,3.71,3.71,0,0,0-.25-.95c-.13-.34-.33-.59-.45-.53s-.39.37-.46.68a5.65,5.65,0,0,0-.15,2.72C347.28,378.41,347.59,379,347.51,379Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M208.9,263.42s-2.47,15.81,7.11,26.15,19.91,14.89,38.11,14.07,57.49.09,57.49.09,1.66,21,6.21,38.17,13.63,43,13.63,43l17.8-1.7s-4.81-93.58-6.05-101.85-15-12.46-22.49-14.94-50.16-11.05-50.16-11.05l-38.77-5.44Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M349.25,383.16l-.31,0-.93.07-3.62.26-13.63.9h-.12l0-.12c-2.39-7.66-5.49-17.61-9.08-29.09-1.79-5.75-3.69-11.89-5.69-18.31-1-3.2-2.07-6.49-2.8-9.93a67,67,0,0,1-1.49-10.6,50.67,50.67,0,0,1,.67-12.5l.23.3c-10.85-.22-21.07-.33-30.29-.36-4.61,0-9,0-13,.07s-7.84.21-11.29.21c-6.88,0-12.39-.35-16.18-.68-1.89-.16-3.36-.32-4.34-.43l-1.13-.15-.38-.06a2.29,2.29,0,0,1,.39,0l1.12.1,4.35.35c3.79.27,9.3.58,16.17.52,3.43,0,7.21-.2,11.28-.27s8.44-.11,13-.11c9.23,0,19.45.09,30.3.31h.29l-.06.3a50.77,50.77,0,0,0-.65,12.35,67.26,67.26,0,0,0,1.47,10.53c.74,3.41,1.8,6.67,2.79,9.88,2,6.43,3.87,12.57,5.65,18.32,3.53,11.51,6.59,21.47,8.95,29.15l-.15-.11,13.64-.76,3.62-.18.93,0A1.57,1.57,0,0,1,349.25,383.16Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M321,293c.1.1-2.11,2.24-4.37,5.25s-3.7,5.73-3.84,5.66a7.32,7.32,0,0,1,.82-1.85,31,31,0,0,1,2.63-4.1,31.78,31.78,0,0,1,3.21-3.67A6.35,6.35,0,0,1,321,293Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M325.83,294.68a39,39,0,0,0-7,3.58,39.82,39.82,0,0,0-5.84,5.32,8.45,8.45,0,0,1,1.35-1.92,22,22,0,0,1,9.26-6.4A7.75,7.75,0,0,1,325.83,294.68Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M246.39,168.49c1.31.65,28.62,17.35,29.92,17.35s33.61-11.79,33.61-11.79l6.62,10.22s-28.33,21.82-37.61,22.14-24-7.13-24-7.13Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M189.57,177.66S155.58,212.22,156,221s4.64,13.63,7.34,14.77c3.77,1.6,46.33-3.64,46.33-3.64l2.52-16.56c-7-1.39-15.87-2.44-25.48-3.36,4.31-4.51,9.1-7.93,15-8.93l10.18-11.07S199.17,175.5,189.57,177.66Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M187.81,188s-1.63-7.46,2-10.45c6-5,11.12-7.27,15.48-9.44,5-2.49,10.29-5.37,10.29-5.37l30.85,5.74s16.47,26.3,16.12,32.77a17.17,17.17,0,0,1-4.45,10.54,39.77,39.77,0,0,0,2.25,21.1c3.69,9.17,10.24,22.42,10.24,22.42s-35.36-5.74-52.13,2.47c-9,4.4-9.81,11.41-9.81,11.41l-1.31-38.52h0l-6.93-23.59Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M211.18,215.36s-.16-.06-.47-.12-.76-.15-1.36-.24c-1.2-.18-2.93-.39-5.08-.64-4.3-.49-10.25-1.09-16.82-1.72l-1.13-.11-.61-.06.48-.38c2.49-2,4.83-3.84,7-5.53a17.54,17.54,0,0,1,6.65-2.93,17,17,0,0,1,4.87-.32,12.22,12.22,0,0,1,1.31.18,1.53,1.53,0,0,1,.44.11,21.08,21.08,0,0,0-6.55.36,17.55,17.55,0,0,0-6.45,3l-7,5.56-.13-.44,1.14.11c6.57.63,12.52,1.29,16.81,1.87S211.25,215.14,211.18,215.36Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M174.66,213a4,4,0,0,1,1.38-1.22,8.88,8.88,0,0,1,4.25-1.35,8.62,8.62,0,0,1,4.36.91,4,4,0,0,1,1.5,1.07c-.05.07-.62-.33-1.65-.76a9.78,9.78,0,0,0-4.19-.73,9.68,9.68,0,0,0-4.09,1.16C175.25,212.57,174.71,213,174.66,213Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M207,191.86c-.09,0-.39-1.26-1.13-3.24a22.45,22.45,0,0,0-4.14-7,14.12,14.12,0,0,0-3.5-2.77l-.9-.45c-.29-.13-.6-.22-.89-.32a9.6,9.6,0,0,0-1.65-.43,12.29,12.29,0,0,0-2.47-.2,3.51,3.51,0,0,1-.93,0,2.93,2.93,0,0,1,.92-.15,10.41,10.41,0,0,1,4.26.43c.3.1.62.18.93.31l1,.45a14,14,0,0,1,3.65,2.84,20.82,20.82,0,0,1,4.1,7.25,19,19,0,0,1,.66,2.44A3.66,3.66,0,0,1,207,191.86Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M257.13,212.79s.41-.46,1.08-1.34a15.59,15.59,0,0,0,2.29-4,16.82,16.82,0,0,0,1.18-6.73,15.74,15.74,0,0,0-2.45-7.94,60.9,60.9,0,0,0-5.17-6.73,30.89,30.89,0,0,1-3.95-5.78,14,14,0,0,1-1.45-4.49,8.42,8.42,0,0,1-.08-1.28,1.34,1.34,0,0,1,0-.45s0,.62.24,1.7a14.84,14.84,0,0,0,1.57,4.36,31.4,31.4,0,0,0,4,5.65,57.66,57.66,0,0,1,5.24,6.76,15.94,15.94,0,0,1,2.48,8.19,16.6,16.6,0,0,1-1.31,6.87,14.66,14.66,0,0,1-2.46,4,8.33,8.33,0,0,1-.89.93A1.71,1.71,0,0,1,257.13,212.79Z",
              },
            }),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("path", {
                attrs: {
                  d: "M203.38,214a4.62,4.62,0,0,1,2.83-5.63,24.11,24.11,0,0,1,3.72-.48c.78-.13,1.65-.52,1.81-1.3.2-1-.81-1.72-1.71-2.11a15.75,15.75,0,0,0-11.67-.28,18.53,18.53,0,0,1,3.65,9.66",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("path", {
                attrs: {
                  d: "M250.14,179.76c-1.59-3.19,4.18,5.77,6.87,8.11a59.34,59.34,0,0,1,7.76,7.35c2.23,2.78,3.88,5.86,3.57,9.42l-6.42-2.26s.81-5.19-2-9S250.78,181,250.14,179.76Z",
                },
              }),
            ]),
            _c("g", { staticStyle: { opacity: "0.30000000000000004" } }, [
              _c("path", {
                attrs: {
                  d: "M186.21,212c-1.77-1.92-4.48-2.7-7.08-3a10.88,10.88,0,0,0-4.72.31,5.08,5.08,0,0,0-3.32,3.17,1.52,1.52,0,0,0,.12,1.38,1.33,1.33,0,0,0,1.46.34,5.1,5.1,0,0,0,1.37-.84A12.17,12.17,0,0,1,186,212.09",
                },
              }),
            ]),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M196.72,187.46a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,196.72,187.46Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M208.05,173.52a2.11,2.11,0,1,1-2.11-2.11A2.11,2.11,0,0,1,208.05,173.52Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M230.11,174.39a2.11,2.11,0,1,1-2.1-2.1A2.1,2.1,0,0,1,230.11,174.39Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M247.17,173.07a2.11,2.11,0,1,1-2.11-2.11A2.11,2.11,0,0,1,247.17,173.07Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M275.39,198.05a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,275.39,198.05Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M258.17,195.94a2.11,2.11,0,1,1-2.11-2.1A2.1,2.1,0,0,1,258.17,195.94Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M261.8,181.93a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,261.8,181.93Z",
              },
            }),
            _c("circle", {
              staticStyle: { fill: "#263238" },
              attrs: { cx: "285.48", cy: "187.66", r: "2.11" },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M302.19,189.56a2.11,2.11,0,1,1-2.1-2.1A2.11,2.11,0,0,1,302.19,189.56Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M249.36,212.1a2.11,2.11,0,1,1-2.11-2.1A2.1,2.1,0,0,1,249.36,212.1Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M257,224.06a2.11,2.11,0,1,1-2.11-2.1A2.1,2.1,0,0,1,257,224.06Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M241.29,232.11a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,241.29,232.11Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M231.1,245.34a2.11,2.11,0,1,1-2.1-2.1A2.1,2.1,0,0,1,231.1,245.34Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M256.06,243.1A2.11,2.11,0,1,1,254,241,2.1,2.1,0,0,1,256.06,243.1Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M215.22,255a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,215.22,255Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M224,222a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,224,222Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M216.92,237.33a2.11,2.11,0,1,1-2.11-2.11A2.11,2.11,0,0,1,216.92,237.33Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M242.62,190.37a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,242.62,190.37Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M229.49,207.19a2.11,2.11,0,1,1-2.11-2.11A2.1,2.1,0,0,1,229.49,207.19Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M216.92,193.84a2.11,2.11,0,1,1-2.11-2.11A2.11,2.11,0,0,1,216.92,193.84Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M165,226.43a2.11,2.11,0,1,1-2.11-2.1A2.12,2.12,0,0,1,165,226.43Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M174.76,199.79a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,174.76,199.79Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M193.48,229.41a2.11,2.11,0,1,1-2.11-2.1A2.11,2.11,0,0,1,193.48,229.41Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M206.31,219.57a2.11,2.11,0,1,1-2.1-2.1A2.11,2.11,0,0,1,206.31,219.57Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M180.7,217.29a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,180.7,217.29Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M191.65,203.37a2.11,2.11,0,1,1-2.1-2.11A2.1,2.1,0,0,1,191.65,203.37Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M182.78,324.93a60.52,60.52,0,0,1-12.93-1.4c-8.82-1.94-15.73-4.79-21-8.69a66.76,66.76,0,0,1-20.45,3.71c-22.68.61-43.21-6.48-68.38-26.85q-1.9-4.06-3.66-8.59c24.23,19.61,50.46,31,71.91,30.46A62.52,62.52,0,0,0,144.44,311c-5.85-6.1-8.8-14.07-8.76-23.75,0-10.61,4.56-15.42,8.34-17.58,5.35-3.07,12.67-2.65,19.09,1.09,7.08,4.13,11.29,11.11,11.26,18.68a20.52,20.52,0,0,1-8.08,16,61.48,61.48,0,0,1-11.89,7.15A59.18,59.18,0,0,0,194,318.83c21.31-4.23,39.11-11.79,52.9-22.47a85.91,85.91,0,0,0,33.52-70.76v-.09c-1.23-24.65-15.62-48.73-36.68-61.35-18.26-10.95-41.66-13.95-65.88-8.45-15.05,3.42-35.53,11.53-47.6,30.76-13,20.72-9.48,46.43,1.25,58.38,7.65,8.53,22.31,5.45,34.1,3l3.88-.8c12.67-2.52,64.08-26.63,64.28-26.7l1.09,4.1c-.83.29-51.38,24.9-64.4,27.49-1.24.24-2.52.51-3.83.79-13,2.73-29.26,6.13-38.83-4.53-6.92-7.72-10.77-18.86-10.84-31.37a63,63,0,0,1,9.08-33c13-20.67,34.75-29.34,50.72-33,25.47-5.78,50.17-2.58,69.54,9C268.7,173.33,284,199,285.36,225.22c.11,1.34,1.45,20.46-8.78,42.1a90.28,90.28,0,0,1-26.66,33c-14.41,11.16-32.9,19-55,23.42A62.06,62.06,0,0,1,182.78,324.93Zm-30.71-52.31a11.19,11.19,0,0,0-5.58,1.39c-3.79,2.17-5.8,6.76-5.83,13.28,0,9.33,2.89,16.54,9,21.9a56.75,56.75,0,0,0,13.56-7.7,15.54,15.54,0,0,0,6.15-12.05c0-5.77-3.26-11.13-8.79-14.35A17.13,17.13,0,0,0,152.07,272.62Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M152.83,317.42a5.8,5.8,0,0,1-1.87-.91,34,34,0,0,1-4.05-3,31.49,31.49,0,0,1-3.61-3.5,5.41,5.41,0,0,1-1.17-1.73c.06,0,.53.6,1.43,1.5s2.18,2.1,3.66,3.35,2.89,2.33,3.93,3.06A14.66,14.66,0,0,1,152.83,317.42Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M158.07,314.5a6.06,6.06,0,0,1-2.25-.57,18.92,18.92,0,0,1-4.8-2.87,23.91,23.91,0,0,1-3.91-4,7.34,7.34,0,0,1-1.22-2c.06,0,.56.68,1.49,1.75a30.52,30.52,0,0,0,4,3.82,22.86,22.86,0,0,0,4.63,2.94C157.23,314.21,158.09,314.42,158.07,314.5Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M138.11,297.27a2.55,2.55,0,0,1-.32-.81,22.73,22.73,0,0,1-.58-2.3,29.66,29.66,0,0,1,1.09-15.44,20,20,0,0,1,.89-2.19,3.12,3.12,0,0,1,.42-.76c.08,0-.41,1.17-1,3.06a35.21,35.21,0,0,0-1.41,7.58,34.63,34.63,0,0,0,.34,7.69C137.86,296.06,138.19,297.25,138.11,297.27Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M125.66,315.17a8.65,8.65,0,0,1-2.41.42,26.3,26.3,0,0,1-11.61-1.65,9,9,0,0,1-2.2-1.07,19,19,0,0,1,2.32.74,33.38,33.38,0,0,0,5.66,1.32,34.06,34.06,0,0,0,5.81.31A20.17,20.17,0,0,1,125.66,315.17Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M263.53,282a12,12,0,0,1-1.46,2.18,53,53,0,0,1-8.82,9.05,11.49,11.49,0,0,1-2.14,1.51A17.69,17.69,0,0,1,253,293c1.2-1.07,2.83-2.58,4.55-4.33s3.18-3.44,4.22-4.67A18.22,18.22,0,0,1,263.53,282Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M120.9,231.09a6.73,6.73,0,0,1-.51-1.33,35.07,35.07,0,0,1-1-3.73,48.82,48.82,0,0,1-1-5.66,51.13,51.13,0,0,1,.37-14.05,49,49,0,0,1,1.28-5.61,38.41,38.41,0,0,1,1.25-3.67,6.84,6.84,0,0,1,.58-1.3,8.88,8.88,0,0,1-.4,1.37c-.3.86-.68,2.12-1.09,3.69a53.94,53.94,0,0,0-1.17,5.59,62,62,0,0,0-.63,7,57.46,57.46,0,0,0,.27,7,53.82,53.82,0,0,0,.87,5.64c.33,1.59.64,2.87.89,3.74A8.53,8.53,0,0,1,120.9,231.09Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M126.94,186.2a8.73,8.73,0,0,1-1.21,2.67,8.91,8.91,0,0,1-1.65,2.43,8.91,8.91,0,0,1,1.22-2.67A9.29,9.29,0,0,1,126.94,186.2Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M248,142.1c-1.19,1.9-.17,4.41,1.16,6.21s3,3.53,3.3,5.75c.35,3.05-2.23,5.72-5,7a5.39,5.39,0,0,0-2.36,1.47c-.78,1.08-.46,2.56-.49,3.89a6.45,6.45,0,0,1-3.32,5.57,5.38,5.38,0,0,1-6.27-1,7.73,7.73,0,0,1-1.62-3.6,16.37,16.37,0,0,1,3.57-14.18c1.69-1.86,3.82-3.26,5.67-5s3.46-3.9,3.62-6.41",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M247.66,119.34c-1.39-3.58-5-5.79-8.66-7.06a24.5,24.5,0,0,0-16.25-.35c-7.62,2.76-12.74,9.86-16.68,16.94-1.71,3.08-3.63,6.52-7,7.55-1.8.54-3.73.29-5.6.5a11.49,11.49,0,0,0-7.48,4c-2.24,2.72-3.4,6.63-6.62,8.06-2.08.92-4.47.53-6.74.6-4.73.15-9.71,3-10.75,7.61-.69,3,.35,6.38-1,9.13-.5,1-1.3,1.84-1.78,2.85a5.19,5.19,0,0,0,7.55,6.55,13.58,13.58,0,0,1,2.07-1.42c1.64-.69,3.51.22,4.94,1.28s2.84,2.36,4.61,2.56c3.62.4,6.43-4,10.07-3.72,2,.14,3.65,1.67,5.4,2.72,3.06,1.84,4.6.19,7.19-2.26s4,7.87,10,5.18c5.58-2.52.06-12.07,2.1-11.85s7.4-.11,8.54-1.82",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M248.36,130a13.58,13.58,0,0,0-1.58-12.31,17.78,17.78,0,0,0-7.64-5.82,19.11,19.11,0,0,0-12.75-1.18c-4.18,1.22-7.85,4.57-8.78,8.82-.42,1.93-.3,4-1.14,5.79a4.31,4.31,0,0,0-.78,2.41,2,2,0,0,0,1.6,1.54,4.48,4.48,0,0,0,2.32-.2,24.41,24.41,0,0,0,7-3.89",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#b78876" },
              attrs: {
                d: "M234.45,167.7c.47-3.69.64-5.2.64-5.17s7.93-.41,10.69-10.38c1.38-5,2.06-13.27,2.41-20.09.3-6.13-2.89-14.07-9-14.11l-19.5,2.64a3.59,3.59,0,0,0-3.61,3.26L213,168.25s8.8,10.42,16.44,9S234.45,167.7,234.45,167.7Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M244.93,138.9a1.31,1.31,0,0,1-1.4,1.17,1.25,1.25,0,0,1-1.21-1.33,1.31,1.31,0,0,1,1.4-1.17A1.25,1.25,0,0,1,244.93,138.9Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M245.9,136.17c-.18.15-1.08-.69-2.49-.85s-2.51.43-2.64.24.14-.37.62-.65a3.67,3.67,0,0,1,2.14-.4,3.39,3.39,0,0,1,1.95.89C245.88,135.78,246,136.1,245.9,136.17Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M232.13,137.57a1.32,1.32,0,0,1-1.41,1.18,1.26,1.26,0,0,1-1.21-1.33,1.31,1.31,0,0,1,1.41-1.17A1.25,1.25,0,0,1,232.13,137.57Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M232.09,134.86c-.18.16-1.08-.69-2.48-.84s-2.51.43-2.65.24.14-.37.62-.65a3.67,3.67,0,0,1,2.14-.4,3.51,3.51,0,0,1,2,.88C232.07,134.47,232.18,134.8,232.09,134.86Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M236,145.84a9,9,0,0,1,2.32-.16c.37,0,.71,0,.8-.26a1.84,1.84,0,0,0-.12-1.09q-.36-1.35-.75-2.85a47.51,47.51,0,0,1-1.57-7.39c.17,0,1.13,3.2,2.16,7.25.25,1,.48,1.95.71,2.86a2,2,0,0,1,0,1.42.9.9,0,0,1-.64.46,3.36,3.36,0,0,1-.62,0A9.52,9.52,0,0,1,236,145.84Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M235.09,162.53a25.94,25.94,0,0,1-13.19-5.17s2.62,7.42,12.89,7.62Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M235,149.15a2.53,2.53,0,0,0-2.15-1.2,2.3,2.3,0,0,0-1.69.68,1.44,1.44,0,0,0-.3,1.65,1.7,1.7,0,0,0,1.78.68,5.33,5.33,0,0,0,1.91-.87,1.36,1.36,0,0,0,.45-.37.48.48,0,0,0,.06-.52",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M232.76,129.78c-.18.36-1.56,0-3.22,0s-3,.31-3.23-.05c-.08-.18.19-.51.77-.82a5.59,5.59,0,0,1,4.92,0C232.57,129.27,232.84,129.6,232.76,129.78Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M246.19,130c-.28.29-1.21-.15-2.37-.3s-2.17,0-2.36-.38c-.08-.18.11-.48.58-.73a3.46,3.46,0,0,1,3.8.55C246.22,129.5,246.32,129.85,246.19,130Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#b78876" },
              attrs: {
                d: "M217,141.19c.08-.76-.59-2.64-1.32-2.84-2-.54-5.58-.79-6.18,4.28-.84,6.91,6.28,6.32,6.32,6.13S216.71,143.8,217,141.19Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M214,145.88s-.13.07-.35.14a1.19,1.19,0,0,1-.9-.09,3,3,0,0,1-1.06-2.84,4,4,0,0,1,.55-1.69,1.38,1.38,0,0,1,1-.81.61.61,0,0,1,.67.41c.07.21,0,.34,0,.35s.16-.09.14-.39a.73.73,0,0,0-.22-.46.87.87,0,0,0-.64-.26,1.71,1.71,0,0,0-1.39.92,4.09,4.09,0,0,0-.65,1.88c-.12,1.4.43,2.79,1.4,3.2a1.27,1.27,0,0,0,1.12-.05C214,146.05,214,145.9,214,145.88Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M240.25,116.86a9.52,9.52,0,0,1-7.11,6.64c-1.79.37-3.9.31-5.07,1.71s-.79,3.7-1.92,5.18c-1.57,2.06-5.27,1.6-6.52,3.86-.54,1-.42,2.24-1,3.22a3.23,3.23,0,0,1-4,1.13,5.16,5.16,0,0,1-2.73-3.4,14,14,0,0,1-.33-4.47,24.73,24.73,0,0,1,1.55-8.68c2.42-5.7,11.71-13,17.82-12",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M247.22,144.17c1.58-1.87,3.08-4,4.67-5.83s3.05-3.68,3.24-6a9.67,9.67,0,0,0-1.69-5.52,69.38,69.38,0,0,0-6.59-9A9,9,0,0,0,245,116a4.48,4.48,0,0,0-2.09-.7,2.52,2.52,0,0,0-1.74.42,1.39,1.39,0,0,0-.51,1.61,2.65,2.65,0,0,0,1.32,1c2.88,1.47,4.73,4.48,5.55,7.61s.59,6.15.52,9.39c-.06,2.53-.4,5.22-.66,7.55",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M231,110.25a21,21,0,0,0-19.19,8.08c-2.41,3.24-4.19,7.46-8,8.82-3.11,1.11-6.6-.13-9.86.45a9.12,9.12,0,0,0-6.69,5.3c-.93,2.18-1,4.8-2.67,6.52-2.14,2.27-6,2-8.23,4.25-1.8,1.84-1.86,4.8-1.14,7.28a4.08,4.08,0,0,0,1.7,2.61,4.18,4.18,0,0,0,2.73.21,31,31,0,0,0,8.92-3.53c6.37-3.42,12.5-7.27,18.63-11.11a38.16,38.16,0,0,0,8-6.07c3-3.26,4.75-7.43,6.85-11.34s4.79-7.77,8.81-9.62",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M162.3,176.19a.93.93,0,0,1-.28-.09,3.13,3.13,0,0,1-.77-.38,5,5,0,0,1-1.89-2.65A6.16,6.16,0,0,1,160,168a10.47,10.47,0,0,1,5.43-4.13,52.46,52.46,0,0,1,8.12-1.78,19.2,19.2,0,0,0,4.36-1.34,8.74,8.74,0,0,0,5.5-7.55,38.16,38.16,0,0,1,.9-5.32,13.68,13.68,0,0,1,7.36-8.4,13,13,0,0,1,5.66-1.22c1.91,0,3.76.34,5.56.46a12.36,12.36,0,0,0,5.2-.55,6.4,6.4,0,0,0,2.15-1.28,7.68,7.68,0,0,0,1.47-2c.79-1.44,1.31-3,2.17-4.4a11.11,11.11,0,0,1,3.31-3.31,26.78,26.78,0,0,1,7.75-3,47.28,47.28,0,0,0,6.54-2,16.41,16.41,0,0,0,4.42-2.57,9.76,9.76,0,0,0,2.07-2.47c.37-.64.51-1,.54-1a6.8,6.8,0,0,1-.45,1,9,9,0,0,1-2,2.56,15.88,15.88,0,0,1-4.44,2.68,45.1,45.1,0,0,1-6.55,2,26.73,26.73,0,0,0-7.61,3,10.49,10.49,0,0,0-3.17,3.19c-.83,1.32-1.33,2.89-2.14,4.39a7.82,7.82,0,0,1-1.56,2.09,6.78,6.78,0,0,1-2.29,1.38,12.76,12.76,0,0,1-5.4.59c-1.84-.12-3.67-.43-5.53-.45a12.45,12.45,0,0,0-5.45,1.18,13.18,13.18,0,0,0-7.11,8.08,39.61,39.61,0,0,0-.9,5.26,10.32,10.32,0,0,1-1.83,4.87,10.21,10.21,0,0,1-3.95,3,19,19,0,0,1-4.46,1.35,53.67,53.67,0,0,0-8.08,1.71,10.22,10.22,0,0,0-5.31,4,6,6,0,0,0-.67,4.87,5,5,0,0,0,1.77,2.62A10.58,10.58,0,0,0,162.3,176.19Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M162.92,154.94a2.71,2.71,0,0,1,.7-.68,4.78,4.78,0,0,1,2.67-.83,13.28,13.28,0,0,1,4.38.74,17.41,17.41,0,0,0,2.67.61,5.86,5.86,0,0,0,3-.29,6.2,6.2,0,0,0,2.56-2.05,11.4,11.4,0,0,0,1.66-3.22c.43-1.19.76-2.47,1.21-3.76a25,25,0,0,1,1.73-3.87,14.62,14.62,0,0,1,6.35-6.39,11.73,11.73,0,0,1,4.73-1c1.63-.05,3.26.06,4.89-.09a7.19,7.19,0,0,0,4.34-1.72,13,13,0,0,0,2.59-3.86,19.77,19.77,0,0,1,2.38-4,11.33,11.33,0,0,1,3.53-2.75,22.21,22.21,0,0,1,7.94-2c2.55-.25,4.93-.34,7.07-.65a18.78,18.78,0,0,0,5.57-1.57,12.21,12.21,0,0,0,3.57-2.52,8.5,8.5,0,0,0,1.59-2.25c.28-.57.39-.9.41-.89a4.56,4.56,0,0,1-.32.93,8.19,8.19,0,0,1-1.54,2.34,12,12,0,0,1-3.59,2.63,18.54,18.54,0,0,1-5.64,1.67c-2.16.34-4.55.45-7.08.71a22,22,0,0,0-7.77,2,10.77,10.77,0,0,0-3.38,2.65,19.2,19.2,0,0,0-2.31,3.9,13.33,13.33,0,0,1-2.69,4,7.67,7.67,0,0,1-4.62,1.85c-1.69.15-3.33,0-4.93.09a11.15,11.15,0,0,0-4.54.94,14.28,14.28,0,0,0-6.16,6.17,26,26,0,0,0-1.71,3.79c-.46,1.27-.79,2.55-1.24,3.76-.83,2.41-2.33,4.62-4.47,5.45a6.22,6.22,0,0,1-3.14.28,17.81,17.81,0,0,1-2.7-.65,13.35,13.35,0,0,0-4.3-.82,5,5,0,0,0-2.63.72C163.15,154.69,162.94,155,162.92,154.94Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#455a64" },
              attrs: {
                d: "M193.35,176s.16,0,.42-.19a1.81,1.81,0,0,0,.78-1,2.17,2.17,0,0,0-.32-2,5.26,5.26,0,0,0-2.17-1.67,10.84,10.84,0,0,1-3-1.75,6,6,0,0,1-1.68-3.68,7.59,7.59,0,0,1,.9-4.37,9.86,9.86,0,0,1,3.33-3.44,23.31,23.31,0,0,1,8.66-2.91,37.14,37.14,0,0,0,7.16-1.79,12.84,12.84,0,0,0,4.19-2.6c.85-.82,1.19-1.38,1.23-1.35s-.06.15-.23.41a7.63,7.63,0,0,1-.86,1.07,12.38,12.38,0,0,1-4.19,2.79,35.21,35.21,0,0,1-7.21,1.91c-2.73.54-5.8,1.14-8.48,2.88a9.52,9.52,0,0,0-3.18,3.26,7.25,7.25,0,0,0-.87,4.1,5.55,5.55,0,0,0,1.52,3.42,10.8,10.8,0,0,0,2.9,1.74,5.41,5.41,0,0,1,2.25,1.83,2.36,2.36,0,0,1,.27,2.16,1.84,1.84,0,0,1-.91,1A.91.91,0,0,1,193.35,176Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#b78876" },
              attrs: {
                d: "M205.91,228.18s-1.15-1.25-.77-2.41,1.06-2,1.35-1.92.19-6.26,1.35-6.74a16.6,16.6,0,0,1,2.5-.77s1.35-3.46,2.12-3.37,9.33,1.64,9.33,1.64,6.74,7.22,6.35,7.89-.77,1.44-1.83,1.06a12,12,0,0,1-2.07-1.06l3.59,3.93s-.69,3-3.63.88l1.44,1.83s-.87,3.76-5.1.58l-4.33-2.6h-2.79l6.35,3.88s.67,1.91-1.25,1.9-6.76-2.56-6.76-2.56-2.88,1.7-3.85,1.7-1.23-1.07-1.23-1.07Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M224.36,227.52a16.61,16.61,0,0,1-1.49-1.28,32.34,32.34,0,0,1-3.52-3.19,3,3,0,0,0-2.16-1,14.63,14.63,0,0,0-2.09.13,3.93,3.93,0,0,1-2-.09c0-.07.75,0,1.93-.25a11.37,11.37,0,0,1,2.16-.25,3.68,3.68,0,0,1,1.31.26,3.31,3.31,0,0,1,1.18.82c1.23,1.38,2.53,2.41,3.4,3.29A5.14,5.14,0,0,1,224.36,227.52Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#aa6550" },
              attrs: {
                d: "M227.83,226.43a12.11,12.11,0,0,1-1.75-1.71c-1.05-1.09-2.5-2.59-4.14-4.2-.83-.79-1.6-1.6-2.35-2.19a4.34,4.34,0,0,0-2.35-.85c-1.48-.1-2.42,0-2.43,0a2,2,0,0,1,.64-.17,10.28,10.28,0,0,1,1.81-.13,4.59,4.59,0,0,1,2.6.83,30.21,30.21,0,0,1,2.43,2.21c1.65,1.62,3.06,3.16,4,4.31A11.23,11.23,0,0,1,227.83,226.43Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M230.87,147.1c.26-.11.85.81,1.76,1.73s1.81,1.53,1.7,1.79-1.34,0-2.4-1.1S230.62,147.18,230.87,147.1Z",
              },
            }),
            _c("path", {
              staticClass: "color1",
              attrs: {
                d: "M304.68,227.28l-8.89-45.7a3.43,3.43,0,0,0-6.73,1.31l.41,2.08-18.39,4.52a12.27,12.27,0,0,0-8.59,7.67l-11.09,2.73a7.17,7.17,0,0,0-5.25,8.67l1,4-10.85,2.68A5.62,5.62,0,1,0,239,226.19l10.86-2.67.77,3.13a7.17,7.17,0,0,0,8.67,5.25l11.29-2.78,0-.09a12.26,12.26,0,0,0,10.89,2.59l16.32-4,.19,1a3.43,3.43,0,0,0,6.73-1.31Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M304.68,227.28a2.51,2.51,0,0,1,.09.77,3.43,3.43,0,0,1-.78,2.12,3.53,3.53,0,0,1-3.38,1.2A3.5,3.5,0,0,1,298,229c-.13-.44-.19-.89-.29-1.35l.13.08-12,3-3.59.9a13.89,13.89,0,0,1-3.91.54,12.48,12.48,0,0,1-7.88-3l.3-.19,0,.08.05.19-.19,0c-3.81.93-7.8,2-12.09,2.95a7.41,7.41,0,0,1-6.27-2.2,7.26,7.26,0,0,1-1.77-3.05c-.31-1.14-.57-2.28-.86-3.43l.27.17L240.74,226c-.79.17-1.54.41-2.39.54a5.86,5.86,0,0,1-5.77-8.77,6.17,6.17,0,0,1,4.29-2.89l10.22-2.51-.18.29c-.2-.83-.41-1.69-.63-2.56a16.7,16.7,0,0,1-.55-2.73,7.4,7.4,0,0,1,1.69-5.26,7.33,7.33,0,0,1,2.15-1.77,9.8,9.8,0,0,1,2.6-.9l10.26-2.52-.16.15a12.64,12.64,0,0,1,5.17-6.31,12.78,12.78,0,0,1,3.74-1.53l3.8-.94,14.44-3.53-.16.25c-.11-.54-.21-1.09-.32-1.63a4.53,4.53,0,0,1-.11-1.69A3.63,3.63,0,0,1,294,179a3.58,3.58,0,0,1,2,2.45l2.19,11.31c1.36,7.1,2.55,13.3,3.53,18.43s1.73,9.13,2.26,11.9c.25,1.36.44,2.41.57,3.14.06.35.11.61.14.81a1.44,1.44,0,0,1,0,.28,1.23,1.23,0,0,1-.07-.27c0-.2-.1-.46-.17-.8l-.65-3.14c-.55-2.76-1.35-6.78-2.37-11.87s-2.23-11.32-3.63-18.41q-1.05-5.32-2.23-11.29a3.2,3.2,0,0,0-1.76-2.17,3.2,3.2,0,0,0-4.54,2.42,3.91,3.91,0,0,0,.1,1.55c.11.54.22,1.08.32,1.63l0,.2-.2.05-14.43,3.57c-2.49.69-5.17,1-7.4,2.41a12.19,12.19,0,0,0-5,6.08l-.05.12-.12,0-10.26,2.53a9.2,9.2,0,0,0-2.48.85,7,7,0,0,0-2,1.65,6.88,6.88,0,0,0-1.58,4.91,15.58,15.58,0,0,0,.54,2.65c.21.88.43,1.74.63,2.57l.06.23-.24.06L237,215.36a5.61,5.61,0,0,0-4,2.66,5.37,5.37,0,0,0,5.29,8c.76-.1,1.56-.35,2.34-.53l9.15-2.24.22-.05.05.21c.29,1.15.55,2.31.85,3.42a6.84,6.84,0,0,0,1.66,2.87,7,7,0,0,0,5.9,2.09c4.21-.94,8.27-2,12.06-2.91l-.13.23,0-.08-.14-.57.44.38a12.1,12.1,0,0,0,7.65,2.91,13.45,13.45,0,0,0,3.82-.52l3.59-.87,12-2.91.11,0,0,.11c.1.46.16.93.28,1.34a3.27,3.27,0,0,0,.58,1.11,3.47,3.47,0,0,0,1.91,1.18,3.39,3.39,0,0,0,4.07-3.16A7.52,7.52,0,0,0,304.68,227.28Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M298.16,227.52c-.13,0-2.27-9.46-4.78-21.18s-4.45-21.26-4.31-21.29,2.27,9.45,4.79,21.18S298.3,227.49,298.16,227.52Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M270.55,229c-.13,0-2-7.09-4.26-15.9s-3.91-16-3.78-16,2,7.09,4.26,15.92S270.68,229,270.55,229Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M249.84,223.52a24.76,24.76,0,0,1-1.59-5.4,46.12,46.12,0,0,1-1.1-5.52,25.08,25.08,0,0,1,1.58,5.41A47.14,47.14,0,0,1,249.84,223.52Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M259.7,199.79c0,.07-1.07.23-2.79.59-.86.18-1.88.41-3,.72a15.13,15.13,0,0,0-3.46,1.36A4.79,4.79,0,0,0,248,205a7.4,7.4,0,0,0-.06,3c.22,1.73.51,2.79.44,2.81a9.17,9.17,0,0,1-.79-2.76,7.4,7.4,0,0,1,0-3.16,3.63,3.63,0,0,1,1-1.65,7.8,7.8,0,0,1,1.63-1.2,14.14,14.14,0,0,1,3.58-1.38c1.14-.29,2.18-.48,3-.62A11,11,0,0,1,259.7,199.79Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M286,187.66a3.24,3.24,0,0,1-.95.29c-.61.15-1.51.36-2.62.6l-8.66,1.82a18.33,18.33,0,0,0-4.52,1.58,9.43,9.43,0,0,0-3,2.46,8.17,8.17,0,0,0-1.49,3.26,2.26,2.26,0,0,1,.07-1A6.85,6.85,0,0,1,266,194.2a9.38,9.38,0,0,1,3.05-2.64,17.24,17.24,0,0,1,4.63-1.67c3.4-.73,6.47-1.27,8.71-1.68,1.11-.2,2-.35,2.65-.45A4.4,4.4,0,0,1,286,187.66Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M245.25,215.09a4.83,4.83,0,0,1-1.93.77c-1.25.34-3,.48-4.89.88a9.75,9.75,0,0,0-2.51.88,6.33,6.33,0,0,0-1.71,1.3,9.2,9.2,0,0,0-1.09,1.7,3.11,3.11,0,0,1,.82-1.92,6,6,0,0,1,1.76-1.48,9.05,9.05,0,0,1,2.62-1c1.93-.41,3.69-.49,4.92-.74S245.22,215,245.25,215.09Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M293.49,179.88c0,.08-.37.07-.9.21a3.62,3.62,0,0,0-1.75,1.08,3.5,3.5,0,0,0-.79,1.91c-.06.54,0,.9-.07.91s-.25-.31-.28-.92a3.23,3.23,0,0,1,.76-2.22,3.29,3.29,0,0,1,2.07-1.1C293.14,179.68,293.5,179.82,293.49,179.88Z",
              },
            }),
          ]),
          _c("g", { attrs: { id: "freepik--error-404--inject-17" } }, [
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M296.22,101.19c0,3.62-2.61,5.8-6.93,5.8h-4.56v5.36h-1.79v-17h6.35C293.61,95.37,296.22,97.56,296.22,101.19Zm-1.79,0c0-2.71-1.79-4.26-5.19-4.26h-4.51v8.48h4.51C292.64,105.41,294.43,103.86,294.43,101.19Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M309.24,104.44v7.91h-1.65v-2a4.77,4.77,0,0,1-4.38,2.11c-2.89,0-4.66-1.51-4.66-3.71,0-2,1.26-3.61,4.92-3.61h4v-.78c0-2.18-1.23-3.37-3.61-3.37a6.49,6.49,0,0,0-4.24,1.5l-.78-1.28a8.12,8.12,0,0,1,5.19-1.72C307.38,99.5,309.24,101.14,309.24,104.44Zm-1.72,4.07v-2.08h-4c-2.47,0-3.27,1-3.27,2.28,0,1.48,1.19,2.4,3.22,2.4A4.08,4.08,0,0,0,307.52,108.51Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M325.78,99.59v11.2c0,4.39-2.16,6.38-6.38,6.38a8.88,8.88,0,0,1-5.94-2l.87-1.31a7.62,7.62,0,0,0,5,1.74c3.23,0,4.71-1.48,4.71-4.63v-1.62a5.76,5.76,0,0,1-5,2.47,6.18,6.18,0,1,1,0-12.36,5.76,5.76,0,0,1,5,2.54V99.59Zm-1.68,6.06a4.84,4.84,0,1,0-4.84,4.68A4.55,4.55,0,0,0,324.1,105.65Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M341.7,106.5H331a4.72,4.72,0,0,0,5,4.44,5,5,0,0,0,3.91-1.67l1,1.11A6.74,6.74,0,0,1,329.31,106a6.15,6.15,0,0,1,6.24-6.47c3.58,0,6.18,2.64,6.18,6.47C341.73,106.11,341.7,106.31,341.7,106.5ZM331,105.22h9a4.53,4.53,0,0,0-9,0Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M363.33,105v7.4h-1.72v-7.23c0-2.69-1.41-4.07-3.83-4.07-2.74,0-4.47,1.69-4.47,4.6v6.7h-1.72V99.59h1.65v2.35a5.35,5.35,0,0,1,4.85-2.44C361.19,99.5,363.33,101.29,363.33,105Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M366.77,106a6.49,6.49,0,1,1,6.5,6.5A6.27,6.27,0,0,1,366.77,106ZM378,106a4.75,4.75,0,1,0-4.72,5A4.65,4.65,0,0,0,378,106Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M390,111.57a4.09,4.09,0,0,1-2.69.9,3.38,3.38,0,0,1-3.74-3.71v-7.71h-2.27V99.59h2.27V96.81h1.73v2.78h3.87v1.46h-3.87v7.61a2,2,0,0,0,2.2,2.33,2.81,2.81,0,0,0,1.89-.66Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M401.41,98.09v1.5h3.93v1.46h-3.88v11.3h-1.72v-11.3h-2.28V99.59h2.28V98a3.51,3.51,0,0,1,3.85-3.78,4,4,0,0,1,2.55.8l-.58,1.28a3,3,0,0,0-1.89-.63C402.19,95.69,401.41,96.51,401.41,98.09Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M406.16,106a6.49,6.49,0,1,1,6.5,6.5A6.28,6.28,0,0,1,406.16,106Zm11.23,0a4.75,4.75,0,1,0-4.73,5A4.66,4.66,0,0,0,417.39,106Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M434.14,99.59v12.76h-1.65V110a5.08,5.08,0,0,1-4.58,2.45c-3.25,0-5.38-1.82-5.38-5.48v-7.4h1.72v7.23c0,2.71,1.4,4.09,3.83,4.09,2.66,0,4.34-1.72,4.34-4.63V99.59Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M450.7,105v7.4H449v-7.23c0-2.69-1.41-4.07-3.84-4.07-2.73,0-4.46,1.69-4.46,4.6v6.7H439V99.59h1.65v2.35a5.35,5.35,0,0,1,4.85-2.44C448.56,99.5,450.7,101.29,450.7,105Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M467.06,94.36v18h-1.65v-2.53a5.51,5.51,0,0,1-4.92,2.65,6.49,6.49,0,0,1,0-13,5.57,5.57,0,0,1,4.85,2.52V94.36ZM465.36,106a4.75,4.75,0,1,0-4.72,5A4.66,4.66,0,0,0,465.36,106Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M347,72.63h-8.89V84.08h-12.3V72.63H296.42V64.15l25.71-34.59h13.24L311.54,62.35h14.64V52.15h11.91v10.2H347Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M350.74,56.82c0-18,10-28.19,23.37-28.19s23.36,10.2,23.36,28.19S387.58,85,374.11,85,350.74,74.82,350.74,56.82Zm34,0c0-12.38-4.36-17.52-10.67-17.52s-10.6,5.14-10.6,17.52,4.37,17.53,10.6,17.53S384.78,69.21,384.78,56.82Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M453.58,72.63H444.7V84.08H432.39V72.63H403V64.15l25.7-34.59H442L418.14,62.35h14.64V52.15H444.7v10.2h8.88Z",
              },
            }),
          ]),
          _c("g", { attrs: { id: "freepik--Lines--inject-17" } }, [
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M439.18,173.14a.74.74,0,0,1-.18.12l-.57.31-2.21,1.15-8.2,4.19L400.5,192.77l-.18.1-.13-.17c-.76-1.09-1.57-2.24-2.4-3.4l-3.41-4.84.32.07-25.65,14.81-7.64,4.36-2.08,1.15c-.22.12-.39.22-.54.29s-.19.1-.19.09,0,0,.17-.13l.52-.33,2-1.23,7.57-4.48,25.56-14.95.19-.12.13.19c1.1,1.54,2.25,3.18,3.43,4.83.82,1.17,1.63,2.32,2.4,3.4l-.31-.08,27.58-13.72,8.27-4.05,2.25-1.08.58-.26Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M431.89,209.26a2.76,2.76,0,0,1-.54.15l-1.58.35L423.91,211l-19.61,3.8-.24.05,0-.24-.44-2.43c-.09-.55-.19-1.1-.28-1.6l.28.2c-7.95,1.36-15,3-20,4.36-2.53.66-4.55,1.23-6,1.62l-1.61.45a2.73,2.73,0,0,1-.57.12,3,3,0,0,1,.54-.22l1.58-.53c1.39-.45,3.4-1.07,5.92-1.77a202.62,202.62,0,0,1,20.05-4.52l.24,0,0,.24c.09.51.19,1.06.29,1.6.15.84.29,1.66.43,2.43l-.29-.2,19.64-3.65,5.88-1,1.6-.26A2,2,0,0,1,431.89,209.26Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M364.85,174.55c.12.06-2.42,5.56-5.69,12.28s-6,12.12-6.13,12.06,2.43-5.55,5.69-12.28S364.73,174.49,364.85,174.55Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M394.71,201.51c0,.13-6.29,2.09-14.13,4.38s-14.24,4-14.27,3.91,6.28-2.09,14.13-4.38S394.67,201.38,394.71,201.51Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M345.77,157.34a5.27,5.27,0,0,1,.41,1c.26.74.6,1.72,1,2.92.87,2.58,2.05,6.14,3.45,10.33l.1.3h-.32l-7.81-.16.25-.29c.18,1,.38,2,.58,3.11,1.28,7,2.43,13.42,3.29,18.12.39,2.27.71,4.13.94,5.5.09.6.17,1.1.23,1.5a1.91,1.91,0,0,1,.05.53,2.07,2.07,0,0,1-.15-.51c-.08-.4-.19-.89-.32-1.49l-1.09-5.47c-.89-4.69-2.1-11.06-3.44-18.1-.19-1.06-.39-2.1-.57-3.1l-.06-.29h.3l7.81.2-.21.3-3.28-10.39c-.37-1.22-.67-2.21-.89-3A8.13,8.13,0,0,1,345.77,157.34Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M311.24,232.69c-.06-.12,5-2.62,11.33-5.58s11.48-5.26,11.54-5.14-5,2.63-11.33,5.59S311.3,232.81,311.24,232.69Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M334.82,239.94a50.2,50.2,0,0,1,4.32-5.58,53.08,53.08,0,0,1,4.71-5.26,48.61,48.61,0,0,1-4.33,5.58A51,51,0,0,1,334.82,239.94Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M347.53,274.28c-.13,0,.73-9.75,1.94-21.75s2.29-21.72,2.43-21.7-.74,9.75-1.94,21.75S347.67,274.29,347.53,274.28Z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#263238" },
              attrs: {
                d: "M330.18,188.12a22.4,22.4,0,0,1,2.31,4.09,21.86,21.86,0,0,1,1.86,4.31,22.73,22.73,0,0,1-2.3-4.09A22.78,22.78,0,0,1,330.18,188.12Z",
              },
            }),
          ]),
        ]
      ),
      _c(
        "b-button",
        {
          staticClass: "mt-5 d-block d-md-inline-block rounded-10 px-5",
          attrs: { variant: "outline-primary" },
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "Home" })
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm._f("t")("Go Back Home")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }