import { render, staticRenderFns } from "./Business.vue?vue&type=template&id=6acd96f8&"
import script from "./Business.vue?vue&type=script&lang=js&"
export * from "./Business.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Code\\khattab\\firnas\\register\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6acd96f8')) {
      api.createRecord('6acd96f8', component.options)
    } else {
      api.reload('6acd96f8', component.options)
    }
    module.hot.accept("./Business.vue?vue&type=template&id=6acd96f8&", function () {
      api.rerender('6acd96f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Pages/Business.vue"
export default component.exports