import Vue from "vue";

export default {
    namespaced: true,
    state: {
        selected: null,
        list: null,
        events: {
            onChange: {},
        }
    },
    getters: {
        async selected(state, getters) {
            if (!state.selected) state.selected = await window._Store.dispatch("Storage/getString", "_l", { root: true });
            if (state.selected && !getters.list[state.selected]) {
                console.error("Locale Error:", "selected Locale " + state.selected + " dosen't exists !, Available locales:", window._locales);
                state.selected = null;
            }

            if (!state.selected) state.selected = process.env.VUE_APP_DEFAULT_LOCALE || Object.keys(state.list)[0]; //set default locale
            Vue.i18n.set(state.selected);
        },
        list(state) {
            if (!state.list) {
                state.list = {};
                for (var key in window._locales) {
                    state.list[key] = window._locales[key].settings.name;
                }
            }
            return state.list;
        }
    },
    actions: {
        async init({ getters }) {
            return { list: getters.list, locale: await getters.selected };
        },
        setLocale({ state, dispatch }, locale) {
            state.selected = locale;
            dispatch("Storage/setString", { key: "_l", value: locale }, { root: true });
            Vue.i18n.set(state.selected);
            dispatch("fireEvent", "onChange");
        },
        selected({ state }) {
            return state.selected;
        },
        setEvent({ state }, payload) //set call back to fire on event fire
        {
            if (!payload || typeof payload != "object") {
                console.error("Locale error: payload is not object", payload);
                return;
            }

            if (payload.key && payload.event) {
                state.events[payload.event][payload.key] = payload.callBack || null;
            }
        },
        onChangeEvent({ dispatch }, payload) //ex: on change language
        {
            payload.event = "onChange";
            dispatch("setEvent", payload);
        },
        clearEvent({ dispatch, state }, key) //clear all event by key
        {
            if (!key) return;
            for (var event in state.events) dispatch("revokeEvent", { key, event });
        },
        revokeEvent({ state }, payload) {
            if (payload.key && payload.event && state.events[payload.event]) {
                delete state.events[payload.event][payload.key];
            }
        },
        fireEvent({ state, getters }, event) {
            switch (event || null) {
                case 'onChange':
                    if (state.events.onChange) {
                        for (let eventName in state.events.onChange) {
                            if (state.events.onChange[eventName]()) {
                                state.events.onChange[eventName]({ state, getters })
                            }
                        }
                    }
                    break;
            }
        }
    }
}