export default {
    namespaced: true,
    state: {
        countries: null
    },
    getters: {
        countries(state)
        {
            window._Store.dispatch("Areas/getCountries");
            return state.countries;
        }
    },
    actions: {
        async getCountries({state, dispatch}, params)
        {
            let storing = false;
            if(!params)
            {
                storing = true;
                params = {
                    limitedBy: 'all'
                };
            }

            return await this._vm.loadFromURL(dispatch, state, "countries", "countries", params, storing);
        }
    },
}