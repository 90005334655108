<template>
    <div class="pb-0">
        <div v-if="step == 1">
            <form class="form-root mb-15" @submit.prevent="register()">
                <h2 class="title-header col-8" v-if="!login">
                    <strong>
                        {{ 'Get started with' | t }}
                    </strong>
                    {{ 'Email' | t }}
                </h2>
                <h2 class="title-header" v-else>
                    {{ 'Sign in with' | t }} {{ 'Email' | t }}
                </h2>
                <div class="input-line" v-if="!login">
                    <input v-model="form.first_name" type="text" :placeholder="$t('First name')" required
                        :disabled="loading" class="half-line">
                    <input v-model="form.last_name" type="text" :disabled="loading" :placeholder="$t('Last name')" required
                        class="half-line">
                </div>
                <input type="email" v-model="form.email" required :disabled="loading" id="emailadd"
                    :placeholder="$t('Email')" class="full-line">
                <input v-model="form.password" :class="{ 'border-danger text-danger': errors.password }" type="password"
                    :disabled="loading" :placeholder="$t('Password')" required @blur="validate" @keyup="validate"
                    class="full-line">
                <div class="bullets">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="muted hint-msg mb-15" v-if="!login">
                    {{ 'Use 8 or more characters with a mix of letters, numbers & symbols' | t }}
                </div>
                <input v-model="form.password_confirmation" type="password" v-if="!login"
                    :class="{ 'border-danger text-danger': errors.password_confirmation }"
                    :placeholder="$t('Confirm Password')" required @blur="validate" @keyup="validate" :disabled="loading"
                    class="full-line">



                <div class="mt-4 mb-2 d-flex align-center" v-if="!login">
                    <input type="checkbox" id="aggreeBox" required />
                    <label for="aggreeBox" class="hint-msg">
                        {{ 'I Agree to firnas' | t }}
                        <a href="#">
                            {{ 'Terms and conditions' | t }}
                        </a>
                    </label>
                </div>
                <button type="submit" :disabled="loading" class="btn-warning btn btn-lg mb-15 d-block">
                    {{ login ? 'Sign in' : 'Get started' | t }}
                </button>
                <div class="muted text-center hint-msg" v-if="!login">
                    {{ 'Already have an account?' | t }}
                    <a href="#" @click.prevent="login = true;$forceUpdate();">{{ 'Sign in' | t }}</a>
                </div>
                <div class="muted text-center hint-msg" v-else>
                    {{ "You don't have account?" | t }}
                    <a href="#" @click.prevent="login = false;$forceUpdate();">{{ 'Sign up' | t }}</a>
                </div>
            </form>
        </div>
        <div class=" verify" v-else-if="step == 2">
            <h2 class="title-header col-10">
                <strong>
                    {{'Verfiy your email Address'|t}}
                </strong>
            </h2>
            <div class="verify-content">
                <strong class="d-block mb-15 ">
                    {{ "You're almost done" | t }}!
                </strong>
                <p class="mb-15"></p>

                <p>
                    {{ 'An email has been sent to' | t }}
                    <span id="emailaddreess">{{ form.email }}</span>
                    {{ 'to verify your account' | t }}.
                </p>
                <p class="mb-15"></p>

                <p class="mb-15">
                    {{ "In case you don't find the verfication email your account inbox within a few minutes, kindly consider checking your spam folder"|t}}.
                </p>
                <p class="mb-15"></p>
                <a href="#" @click.prevent="register" v-if="!loading">
                    <strong>
                        {{ 'Resend the verfication code' | t }}
                    </strong>
                </a>
                <a href="#" v-else>
                    <b>{{'Sending'|t}}...</b>
                </a>
                <p class="mb-15"></p>
                <p class="mb-15"></p>

                <a href="#" @click.prevent="step = 3;$forceUpdate()" v-if="!loading">
                    <strong class="d-block">
                        {{ 'Continue ->' | t }}
                    </strong>
                </a>

            </div>
        </div>
        <div v-else-if="step == 3">
            <verify-code-form :login="login" :userData="userData" :resend="register" :callBack="afterVerify" :loading="loading" />
        </div>
    </div>
</template>

<script>
import VerifyCodeForm from '@/Components/Auth/VerifyCodeForm.vue';
import { mapActions, mapState } from 'vuex'
export default {
    components: { VerifyCodeForm, },
    data() {
        return {
            form: {},
            loading: false,
            errors: {},
            step: 2,
            userData: {},
            login: false
        }
    },
    computed: {
        ...mapState("User", ["user"]),
    },
    methods: {
        ...mapActions("User", {
            RegisterByEmail: "RegisterByEmail",
            LoginByEmail: "LoginByEmail",
            setUserData: "setData",
            getProfile: "profile",
        }),
        async register() {
            
            this.validate();
            if (this.loading || (!this.login && Object.values(this.errors).filter(val => val === true).length > 0)) return false;
            this.loading = true;
            this.$forceUpdate();
            
            let results = await this.RegisterByEmail({
                ...this.only(["email"], this.form),
                skipExistsUser: true,
                restore_deleted: true
            });

            if (results && results.user_id) {
                this.form.user_id = results.user_id;
                this.userData = this.form;
                this.notify(this.$t('Please verify your mail'));
                this.step = 2;
                this.$forceUpdate();
            }

            this.loading = false;
            this.$forceUpdate();
        },
        validate() {
            if(!this.login)
            {
                this.errors.password = !this.passwordChecker(this.form.password);
                this.errors.password_confirmation = this.form.password && this.form.password != this.form.password_confirmation;
            }
            this.$forceUpdate();
        },
        async afterVerify(results) {
            if (results && results.token) {
                this.setUserData({results});
                console.log("this.user", {...this.user}, {...results});
                await this.getProfile();     
                this.notify(
                        this.$t((this.loading ? "Signed in" : "Signed up")+" Successfully Wellcome") + " "+(this.user.name || ""),
                        "success"
                    );          
                    
                    console.log("afterVerify => this.user.companies_ids:", this.user)
                    if(this.user.companies_ids && this.user.companies_ids.length > 0) {
                        this.$router.go();
                    } else {
                        this.$router.push({ name: "Business" }).catch(()=>{});

                    }
            }

        }
    },
    mounted() {
        this.step = 1;
    },
}
</script>

