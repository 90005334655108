<template>    
        <form class="form-root mb-15" @submit.prevent="saveCompany">
            <h2 class="title-header">
               <strong>
                    {{'Set up your Business'|t}}
               </strong> 
            </h2>
            <div class="mb-15">
                <label class="primary" for="BusinessName">{{'Business name'|t}}</label>
                <input :disabled="loading" type="text" required id="BusinessName" v-model="BusinessName" class="full-line">
            </div>
            <div class="mb-15">                
                <label class="primary" for="Website">{{'Business namespace'|t}}</label>
                <input :disabled="loading" type="text" @keyup="modifyKey" required v-model="form.company_key" id="Website" class="full-line">
                <small class="text-muted" v-if="form.company_key">{{form.company_key}}.{{base}}</small>
            </div>
            <div class="mb-15" v-if="!form.info.noWebsite">
                <label class="primary" for="Website">{{'Website name'|t}}</label>
                <input :disabled="loading" type="text" v-model="form.info.website" required id="Website" class="full-line">
            </div>            
            <div class="mb-1 d-flex align-center">
                <input :disabled="loading" type="checkbox" v-model="form.info.noWebsite" @change="form.info.website = '';$forceUpdate();" id="noWebsite">
                <label for="noWebsite" class="hint-msg">
                    {{"I don't have a Website"|t}}
                </label>
            </div>
            
            <div class="mb-15">
                <label class="primary">
                    {{'Country'|t}}
                </label>
                
                <Countries :loading="loading" @select="setCountryCode" required="required" />
            </div>
            <div class="mb-15" v-if="countryCode">
                <label class="primary" for="Phonenumber">
                    {{'Phone number'|t}}
                </label>
                <div class="input-group">
                    <span class="code">
                        +{{countryCode}}
                    </span>
                    <input :disabled="loading" @blur="form.admin.phone = countryCode.toString()+phone" v-model="phone" type="number" required id="Phonenumber" class="full-line" style="width: auto;">
                </div>
            </div>

            <b-row class=" mt-5">
                <b-col>
                    <h4 class="title-header">
                        <strong>
                            {{'Administrator Login info'|t}}
                        </strong>    
                    </h4>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <label class="primary" for="">{{'Email'|t}}</label>
                    <input type="email" v-model="form.admin.email" required :disabled="loading" id="emailadd"
                    :placeholder="$t('Email')" class="full-line">
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <label class="primary" for="">{{'Password'|t}}</label>
                    <input type="password" v-model="form.admin.password" required :disabled="loading" id="emailadd"
                    :placeholder="$t('Password')" class="full-line">
                </b-col>
            </b-row>

            <button type="submit" class="btn btn-warning btn-lg mb-15 d-block">
                {{'Set up your Business'|t}}
            </button>
        </form>
</template>

<script>
import Countries from '@/Components/Forms/Countries.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: { Countries },
    computed: {
        ...mapState("User", ["user"]),
        ...mapState("Locale", {locales: "list"}),
        ...mapState("Config", ["Http"]),
        form()
        {
            var form = {
                names: {},
                admin: {
                    email: this.user ? this.user.email : null,
                    phone: null,
                    password: null
                },
                info: {
                    noWebsite: false,
                    website: ''
                }
            };

            return form;
        },
        base()
        {
            return this.baseName(this.Http.mainURL)
        }
    },
    data() {        
        return {
            loading: false,
            BusinessName: "",
            website: "",
            countryCode: null,
            phone: null,
            business: null
        }
    },
    methods: {   
        ...mapActions("Business", {createBusiness: "create"}),
        async saveCompany()
        {
            this.loading = true;
            this.$forceUpdate();
            let results = await this.createBusiness(this.form);
            if(results)
            {
                // this.business = results;
                this.notify(this.$t("The Business") + " " + this.BusinessName + " " + this.$t("has been created sucessfully"), "success", null, 20);
                this.$router.push({name: "Home"});
            }
            else
            {
                this.loading = false;
                this.$forceUpdate();
            }
        },
        setCountryCode(country)
        {
            this.countryCode = country && country.info ? country.info.nationality_id || null : null;
            this.$forceUpdate();
            this.$nextTick(() => document.querySelector('#Phonenumber').focus());
        },
        modifyKey()
        {
            this.form.company_key = this.strToKey(this.form.company_key);  
            this.$forceUpdate();
        }
    },
    watch: {
        BusinessName()
        {
            this.form.names = {};
            for(var locale in this.locales)
            {
                this.form.names[locale] = this.BusinessName;
            } 
            
            this.form.company_key = this.strToKey(this.BusinessName);  
        }
    },
    
}
</script>