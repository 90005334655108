import helper from "./helper";
import { BIconXCircle, BIconCheck, BIconExclamation, BIconWifi, BIconWifiOff, BIconClipboardCheck } from "bootstrap-vue"
import {countryCodeEmoji} from "country-code-emoji"
export default {
    methods: {
        ...helper,
        getFlagByCode(code)
        {
            return code && code.constructor == String && code.length > 0 ? countryCodeEmoji(code) : '';
        },
        openUrl(url, options)
        {
            url = typeof options == "string" ? options : (options.url || url || "");
            let target = options == "object" ? options.target || "_blank" : "_blank";

            window.open(url, target);
        },
        goToPage(link) {
            if (link.constructor == String) {
                if (link.startsWith('/')) {
                    this.$router.push(link).catch(() => { });
                } else if (link.startsWith('http')) {
                    window.open(link, '_blank')
                } else {
                    this.$router.push({ name: link }).catch(() => { });
                }
            }
            else if (link.constructor == Object && link.self) window.location.href = link.url;
            else this.$router.push(link).catch(() => { });
        },
        passwordChecker(password)
        {
            var strongRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
            // console.log("pas", password, strongRegex.test(password));
            return strongRegex.test(password);
        },
        isRTL() {
            return this.$i18n.locale() == 'ar' ? true : false
        },
        getErrorMsg(error) {
            if (error.results && error.results.messages_array) {
                return "- " + error.results.messages_array.join("<br> - ")
            } else {
                return this.$t("Somthing wrong", { error })
            }
        },
        getNotfyIcon(type) {
            switch (type) {
                case "error":
                    return BIconXCircle;
                case "success":
                    return BIconCheck;
                case "wifi-off":
                    return BIconWifiOff;
                case "wifi":
                    return BIconWifi;
                case "clipboard-check":
                    return BIconClipboardCheck
                default:
                    return BIconExclamation
            }
        },
        notify(msg, type, icon, time, place) {
            this.$toast.open({
                message: type == "error" ? this.$t(this.getErrorMsg(msg)) : this.$t(msg),
                type: type || "warning",
                duration: (time || 5) * 1000,
                position: place || this.isRTL ? "top-left" : "top-right",
                icon: this.getNotfyIcon(icon || type),
                rtl: this.isRTL
            });
        },
        formatePhoneNumber(phone) {
            let formatedPhone;
            if (phone) {
                for (let index = 0; index < this.countriesList.length; index++) {
                    const element = this.countriesList[index];
                    if (phone.startsWith(element.mobileCode.replace('+', ''))) {
                        formatedPhone = `${element.mobileCode} ${phone.slice(element.mobileCode.replace('+', '').length)}`;
                    }
                }
                return formatedPhone ? formatedPhone : phone
            } else {
                return phone
            }
        },
    },
}