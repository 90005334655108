// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #region Globel Declirations */
* {
  padding   : 0;
  margin    : 0;
  box-sizing: border-box;
  font-family: 'Poppins',
sans-serif;
}
:root {
  --primary1   : #06188F;
  --primary    : #06188F;
  --secondary  : #F2CB05;
  --third      : #21A0AF;
  --gray-border: #E0E0E0;
  --link-color: #0C5AC1;
  --inputs-radius: 10px
}
body{
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.text-center {
  text-align: center;
}
.btn-primary {
  background-color: var(--secondary);
  color: var(--primary);
  text-align: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px 30px;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: none;
}
.muted {
  color: #A3A6BF;
}
a {
  color          : var(--link-color);
  text-decoration: none;
}
.mb-15 {
  margin-bottom: 15px !important;
}
input {
  color    : var(--primary);
  font-size: 14px;
  font-weight: 600;
}
input::-moz-placeholder {
  color: var(--primary1);
  opacity: 1; /* Firefox */
  font-size: 14px;
  font-weight: 600;
}
input::placeholder {
  color: var(--primary1);
  opacity: 1; /* Firefox */
  font-size: 14px;
  font-weight: 600;
}
input::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--primary1);
}
.hint-msg{
  font-size: 13px;
}
label.hint-msg{
  margin: 0 !important;
  padding: 0 5px;
}
.hint-msg a{
  font-size: 15px !important;
}
.d-block{
  display: block;
  width: 100%;
}
input[type=checkbox]{
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin: 0 !important;
  margin-right: 5px !important;
}
label{
  cursor: pointer;
  font-size: 12px;
}
label.primary{
  color: var(--primary1);
  font-weight: 600;
}
.d-flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.locales-dropdwon{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.locales-dropdwon img,
.locales-dropdwon svg
{
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin:0 5px;
}
/* #endregion Globel Declirations */


/* #region Hmoe page style */
.main-wrapper {
  min-height           : 100vh;
  width                : 100vw;
  /* background-image     : linear-gradient(35deg, var(--primary), var(--third)); */
  background: rgb(33,160,175);
  background: linear-gradient(195deg, rgba(33,160,175,1) 1%, rgba(6,24,143,1) 65%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21a0af",endColorstr="#06188f",GradientType=1);
  display              : grid;
  grid-template-columns: 60% 40%;
  padding: 80px;
}
/* .main-wrapper .logo-side{
  padding: 80px;
} */
.main-wrapper .forms-controler {
  background-color     : #fff;
  border-radius        : 55px;
  -webkit-border-radius: 55px;
  -moz-border-radius   : 55px;
  -ms-border-radius    : 55px;
  -o-border-radius     : 55px;
  padding              : 60px;
  max-width: 600px;
  min-width: 600px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 600px;

  /* margin: auto auto;
  margin-bottom: 60px; */
}
.main-wrapper .forms-controler.verify{
  max-width: 517px !important;
}
.main-wrapper .forms-controler .title-header {
  color        : var(--primary1);
  font-size    : 27px;
  margin-bottom: 50px;
}
.main-wrapper .logo-side h1 {
  color    : #fff;
  font-size: 45px;
}
.main-wrapper .forms-controler .form-root .input-line {
  display        : flex;
  justify-content: space-between;
}
.main-wrapper .forms-controler .form-root input:not(input[type=checkbox]),.main-wrapper .forms-controler .form-root select {
  border: 2px solid var(--gray-border);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: var(--inputs-radius);
  -webkit-border-radius: var(--inputs-radius);
  -moz-border-radius: var(--inputs-radius);
  -ms-border-radius: var(--inputs-radius);
  -o-border-radius: var(--inputs-radius);
  transition: .3s linear;
  -webkit-transition: .3s linear;
  -moz-transition: .3s linear;
  -ms-transition: .3s linear;
  -o-transition: .3s linear;
  outline: none;
  display: block;
  width: 100%;
  font-size: 12px;
}
.main-wrapper .forms-controler .form-root input:focus {
  border-color: var(--primary);
}
.main-wrapper .forms-controler .form-root input.half-line {
  display: inline-block;
  width  : calc(98% / 2) !important;
}
.main-wrapper .forms-controler .form-root input.full-line {
  width  : 100%;
  display: block;
}
.main-wrapper .forms-controler .form-root .input-group{
  display: flex;
  align-items: center;
  border: 2px solid var(--gray-border);
  border-radius: var(--inputs-radius);
  -webkit-border-radius: var(--inputs-radius);
  -moz-border-radius: var(--inputs-radius);
  -ms-border-radius: var(--inputs-radius);
  -o-border-radius: var(--inputs-radius);
}
.main-wrapper .forms-controler .form-root .input-group input{
  border: none !important;
  margin: 0 !important;
  padding: 0 10px !important;
  border-radius: unset !important;
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
  -ms-border-radius: unset !important;
  -o-border-radius: unset !important;
}
.main-wrapper .forms-controler .form-root .input-group .code {
  border-right: 2px solid var(--gray-border);
  color: var(--primary);
  padding: 15px;
  font-weight: 600;
  height: 100%;
}
/* #endregion Hmoe page style */
.verify-content{
  color: var(--primary1);
}
/* Media queries */
@media (max-width: 992px) {
.main-wrapper {
    display: block;
    padding: 30px;
}
.main-wrapper .forms-controler {
    min-width: 95%;
    margin-top: 30px;
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
