<template>
  <div>Redirecting ...</div>
</template>

<script>

export default {

  mounted() {
    console.log(window.location);
    window.open(window.location.href, "_blank")
  }
}

</script>