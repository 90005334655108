export default {
    //settings
    settings: {
        name: "English",
        key: "en",
        dir: "ltr",
        xdir: "rtl",
        align: "left",
        xalign: "right"
    },
    ar: "Arabic",
    en: "English",
}