import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n';
import VueCryptojs from 'vue-cryptojs'
import App from '@/App'
import Store from '@/Store'
import Locales from '@/Store/Locale/Locales';
import mixins from '@/Mixin';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueToast from 'vue-toast-notification';
import router from '@/Store/Routes/router';

//crypto
Vue.use(VueCryptojs);


//BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToast);

//Init Vuex
Vue.use(Vuex);

//apply mixins
Vue.mixin(mixins);

//Store
//Store Config
const store = window._Store = new Vuex.Store(Store);

const config = {
    translateFilterName: 't',
    // silentTranslationWarn: true,
}

Vue.use(vuexI18n.plugin, store, config);

// add translations directly to the application
for (var locale in Locales) {
    Vue.i18n.add(locale, Locales[locale]);
}
window._locales = Locales;

//Init Vue 2 App
const MainApp = window._MainApp = new Vue({
    store,
    router,
    render: function (h) { return h(App) },
});

window._app = MainApp;

//Mount app
MainApp.$mount('#app');