<template>
    <b-dropdown variant="default" :class="className || 'w-100'" :text="list[selected]">
        <b-dropdown-item v-for="(lang, k, i) in list" :key="i" @click="setLocale(k)">
            {{lang}}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: [
        "className",
    ],
    computed: {
        ...mapState("Locale", ["list", "selected"])
    },
    methods: {
        ...mapActions("Locale", ["setLocale"])
    },
}
</script>