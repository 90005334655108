<template>
    <div>
        <Register v-if="!user" />
        <div v-else class="verify">
            <h2 class="title-header">
                {{'Welcome'|t}}, {{user.first_name}}
            </h2>
            <h5>
                {{ 'Redirecting to Your Company...' }}
            </h5>
            <button 
                type="button" 
                class="btn btn-outline-primary btn-outline btn-sm my-4" 
                @click="redirectToCompany"
            >
                {{ 'Click here If Not redirected' }}
            </button>

            <!-- <div class="verify-content text-center">
                <button type="button" class="btn btn-outline-primary btn-outline mb-3 btn-lg w-100" 
                    v-for="(company, i) in user && user.companies ? user.companies || [] : []" :key="i" 
                    @click="gotoBusiness(company)">
                    {{'Login to Business'|t}} <b>{{company.name}}</b>
                </button>
                <button type="button" class="btn btn-warning mb-2 btn-lg mt-5 w-100" @click="goToPage('/business')">
                    {{'Create a new Business'|t}}
                </button>
            </div> -->
            
            
            
        </div>

        
    </div>
</template>


<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Register from './Register.vue';
import Locales from '@/Components/Forms/Locales.vue';
import Logout from '@/Components/Auth/Logout.vue';
import Help from '@/Components/Forms/Help.vue';
export default {
    computed: {
        ...mapState("User", ["user"]),
    },
    methods: {
        ...mapActions("User", ["logout"]),
        ...mapActions("Business", ["gotoBusiness"]),
        async redirectToCompany() {
            let currentUser = await this.user;
            if(currentUser !== null && currentUser.companies.length > 0 && this.$route.name == "Home") {
                //* redirect to the first business
                let companyDomain = currentUser.companies[0].urls[0];
                window.open(`${companyDomain}/dashboard` , "_blank")
            }
        }
    },
    mounted() {
        // console.log("this.user", this.user);
        // if(!this.user) 
        // this.$nextTick(() => this.$router.push({ name: "Register" }));
    },
    components: { Register, Locales, Logout, Help }
}
</script>