/**
 * All Locales files 
 */

import en from "./en"
import ar from "./ar"

export default {
    en,
    ar,
}