
export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    getters: {
        async token(state) {
            if (!state.token) state.token = await window._Store.dispatch("Storage/getString", '__t', { root: true });
            return state.token;
        },
        async user(state) {
            if (!state.user) state.user = await window._Store.dispatch("Storage/get", '__u', { root: true });
            return state.user;
        },
        isLogin(state) {
            return state.user && state.user.id;
        },
        id(state) {
            return state.user ? state.user.id : null;
        }
    },
    actions: {
        async init({ getters, dispatch}) {
            await getters.user;
            getters.token;            
            if(getters.user) await dispatch("profile");
            return getters;
        },
        token({ getters }) {
            return getters.token;
        },
        user({ getters }) {
            return getters.user;
        },
        async setData({ state, dispatch }, response) {
            if (!response) dispatch('clear');
            else {
                if (response.results.token) {
                    state.token = response.results.token;
                    delete response.results.token;
                }

                
                // console.log("response.results", response.results);
                state.user = response.results.user || response.results;
                if (!state.user) dispatch('clear');
                console.log("state", state);
                await dispatch("Storage/set", { '__t': state.token, '__u': state.user }, { root: true });
            }

        },
        async clear({ state, dispatch }) {
            state.token = null;
            state.user = null;
            await dispatch("Storage/remove", ['__t', '__u'], { root: true });
        },
        async login({ dispatch, getters }, params) {
            let response = null;
            let isError = false;
            params.skip
            if(!params) return null;
            try {
                /**
                 * ignoreToken: true ==> to ignore token if exists
                 */
                let keyName = params.keyName || 'login';
                if (keyName == 'loginByPhone' || keyName == 'loginByEmail'){
                    params.skipExistsUser=true;
                    params.restore_deleted=true
                }
                if(params.keyName) delete params.keyName;
                
                params.load = [
                        "companies.defaultPackage.package",
                        "companies.defaultPackage.logs",
                        "companies.defaultPackage.payments",
                    ];                    
               
                response = await dispatch('Http/post',
                    { name: keyName, ignoreToken: true, params: params }, { root: true });
                if (response && response.results && response.results.user) dispatch('setData', response);
            }
            catch (error) {
                isError = true;
                response = error;
                console.error("Login error", error);
                await dispatch("clear");
            }

            return new Promise((resolve, reject) => {
                if (isError) reject(response);
                else resolve(response);
            });
        },
        
        async loginByPhone({ dispatch }, params) {
            params.keyName = "loginByPhone";
            return dispatch("login", params);
        },
        async loginByEmail({ dispatch }, params) {
            params.keyName = "loginByEmail";
            return dispatch("login", params);
        },
        
        async logout({ state, getters, dispatch }) {
            let token = state.token;            
            if (token) 
            {
                try
                {
                    await dispatch('Http/post', { name: 'logout' }, { root: true }); 
                }
                catch(e) {}
            }           
            dispatch('clear');
            localStorage.setItem("loginSkiped", JSON.stringify(true))
        },
        async RegisterByEmail({dispatch}, params)
        {
            try
            {
                let res = await dispatch("Http/post", {name: 'loginByEmail', params}, {root: true});
                if(!res.status) dispatch("Errors/handle", res, {root:true});
                return res.results;
            }
            catch(e)
            {
                dispatch("Errors/handle", e, {root: true});
                return null;
            }
        },
        async verifyEmail({dispatch}, params)
        {
            try
            {
                let res = await dispatch("Http/post", {name: 'verifyEmail', params}, {root: true});
                console.log("response", res);
                if(!res.status) dispatch("Errors/handle", res, {root:true});
                return res.results;
            }
            catch(e)
            {
                return null;
            }
        },
        async profile({ dispatch, state, getters }, params) {
            if (state.user) {
                let response = null;
                try {
                    if(!params) 
                        params = {
                            load: [
                                "companies"
                                // "companies.defaultPackage.package",
                                // "companies.defaultPackage.logs",
                                // "companies.defaultPackage.payments",
                                // "companies.packages",
                            ]
                        };
                    response = await dispatch('Http/post', { name: 'profile', params}, { root: true });
                    if (response) dispatch('setData', response);
                }
                catch (error) {
                    // isError = true;
                    // console.error("Profile error", error);
                    dispatch("Errors/handle", error, {root:true})
                    await dispatch("clear");
                }
                return response.results;
            } else {
                return false
            }
        },
        async updateProfile({ dispatch, getters }, params) {
            let response = null;
            let isError = false;
            try {
                response = await dispatch('Http/post', { name: 'updateProfile', params: params }, { root: true });
                if (response) dispatch('setData', response);
            }
            catch (error) {
                isError = true;
                response = error;
                console.error("Update profile error", error);
                await dispatch("clear");
            }

            return new Promise((resolve, reject) => {
                if (isError) reject(response);
                else resolve(response);
            });
        },
    }
}