import Config from "./Config"
import Storage from "./Storage"
import Settings from "./Settings"
import User from "./User"
import Http from "./Http"
import Locale from "./Locale"
import Errors from "./Errors"
import Areas from "./Areas"
import Business from "./Business"

export default {
    namespaced: true,
    modules: {
        Config,
        Storage,
        Settings,
        User,
        Locale,
        Http,
        Errors,
        Areas,
        Business,
    },
    actions: {
        async init({dispatch})
        {
            await dispatch("Locale/init", null, {root: null});
            await dispatch("User/init", null, {root: null});
            await dispatch("Settings/init", null, { root: true });
        }
    }
}