import RoutesList from "./RoutesList";
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: RoutesList,
});


//middleware
/* eslint-disable */
router.beforeEach((to, from, next) => {
    console.log("router.beforeEach => to:", to)
    window._Store.dispatch("User/user").then(user => {
        // console.log("Store", user);
        //check guest or must be login
        if (to.meta &&
            ((to.meta.guest && user) ||
                (to.meta.user && !user))
        )
            // if (window.innerWidth >= 992) {
            //     window._tenantApp.$bvModal.show("theloginmodal")
            // } else {
            next({ name: "Home" });
            // }
        else
            next();
    });

});

// router.afterEach(() => {});
export default router;