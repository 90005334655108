import { BIconXCircle } from "bootstrap-vue";
export default {
    namespaced: true,
    state: {
        messages: [],
        listMessages: {}, //can call by key ... later
    },
    actions: {
        show({state}, message)
        {            
            if(!message) return false;
            if(state.listMessages[message]) message = state.listMessages[message];
            this._vm.$toast.open({
                message, type: "error",
                duration: 5000,
                position: this._vm.$i18n.locale() == 'ar' ? "top-left" : "top-right",
                icon: BIconXCircle,
                rtl: this._vm.$i18n.locale() == 'ar'
            });
            console.error("Error message details", message);
            state.messages.push(message);
        },
        handle({dispatch}, err)
        {
            let message = err.results && err.results.message ? err.results.message : err;
            dispatch("show", message);
        }
    }
}