var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-0" }, [
    _vm.step == 1
      ? _c("div", [
          _c(
            "form",
            {
              staticClass: "form-root mb-15",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.register()
                },
              },
            },
            [
              !_vm.login
                ? _c("h2", { staticClass: "title-header col-8" }, [
                    _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm._f("t")("Get started with")) + " "
                      ),
                    ]),
                    _vm._v(" " + _vm._s(_vm._f("t")("Email")) + " "),
                  ])
                : _c("h2", { staticClass: "title-header" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("t")("Sign in with")) +
                        " " +
                        _vm._s(_vm._f("t")("Email")) +
                        " "
                    ),
                  ]),
              !_vm.login
                ? _c("div", { staticClass: "input-line" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.first_name,
                          expression: "form.first_name",
                        },
                      ],
                      staticClass: "half-line",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("First name"),
                        required: "",
                        disabled: _vm.loading,
                      },
                      domProps: { value: _vm.form.first_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "first_name", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.last_name,
                          expression: "form.last_name",
                        },
                      ],
                      staticClass: "half-line",
                      attrs: {
                        type: "text",
                        disabled: _vm.loading,
                        placeholder: _vm.$t("Last name"),
                        required: "",
                      },
                      domProps: { value: _vm.form.last_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "last_name", $event.target.value)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email",
                  },
                ],
                staticClass: "full-line",
                attrs: {
                  type: "email",
                  required: "",
                  disabled: _vm.loading,
                  id: "emailadd",
                  placeholder: _vm.$t("Email"),
                },
                domProps: { value: _vm.form.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "email", $event.target.value)
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password",
                  },
                ],
                staticClass: "full-line",
                class: { "border-danger text-danger": _vm.errors.password },
                attrs: {
                  type: "password",
                  disabled: _vm.loading,
                  placeholder: _vm.$t("Password"),
                  required: "",
                },
                domProps: { value: _vm.form.password },
                on: {
                  blur: _vm.validate,
                  keyup: _vm.validate,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "password", $event.target.value)
                  },
                },
              }),
              _vm._m(0),
              !_vm.login
                ? _c("div", { staticClass: "muted hint-msg mb-15" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("t")(
                            "Use 8 or more characters with a mix of letters, numbers & symbols"
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              !_vm.login
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password_confirmation,
                        expression: "form.password_confirmation",
                      },
                    ],
                    staticClass: "full-line",
                    class: {
                      "border-danger text-danger":
                        _vm.errors.password_confirmation,
                    },
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("Confirm Password"),
                      required: "",
                      disabled: _vm.loading,
                    },
                    domProps: { value: _vm.form.password_confirmation },
                    on: {
                      blur: _vm.validate,
                      keyup: _vm.validate,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "password_confirmation",
                          $event.target.value
                        )
                      },
                    },
                  })
                : _vm._e(),
              !_vm.login
                ? _c("div", { staticClass: "mt-4 mb-2 d-flex align-center" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "aggreeBox",
                        required: "",
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "hint-msg", attrs: { for: "aggreeBox" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._f("t")("I Agree to firnas")) + " "
                        ),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("t")("Terms and conditions")) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn-warning btn btn-lg mb-15 d-block",
                  attrs: { type: "submit", disabled: _vm.loading },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("t")(_vm.login ? "Sign in" : "Get started")
                      ) +
                      " "
                  ),
                ]
              ),
              !_vm.login
                ? _c("div", { staticClass: "muted text-center hint-msg" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("t")("Already have an account?")) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.login = true
                            _vm.$forceUpdate()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("t")("Sign in")))]
                    ),
                  ])
                : _c("div", { staticClass: "muted text-center hint-msg" }, [
                    _vm._v(
                      " " + _vm._s(_vm._f("t")("You don't have account?")) + " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.login = false
                            _vm.$forceUpdate()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("t")("Sign up")))]
                    ),
                  ]),
            ]
          ),
        ])
      : _vm.step == 2
      ? _c("div", { staticClass: "verify" }, [
          _c("h2", { staticClass: "title-header col-10" }, [
            _c("strong", [
              _vm._v(
                " " + _vm._s(_vm._f("t")("Verfiy your email Address")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "verify-content" }, [
            _c("strong", { staticClass: "d-block mb-15" }, [
              _vm._v(" " + _vm._s(_vm._f("t")("You're almost done")) + "! "),
            ]),
            _c("p", { staticClass: "mb-15" }),
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm._f("t")("An email has been sent to")) + " "
              ),
              _c("span", { attrs: { id: "emailaddreess" } }, [
                _vm._v(_vm._s(_vm.form.email)),
              ]),
              _vm._v(
                " " + _vm._s(_vm._f("t")("to verify your account")) + ". "
              ),
            ]),
            _c("p", { staticClass: "mb-15" }),
            _c("p", { staticClass: "mb-15" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("t")(
                      "In case you don't find the verfication email your account inbox within a few minutes, kindly consider checking your spam folder"
                    )
                  ) +
                  ". "
              ),
            ]),
            _c("p", { staticClass: "mb-15" }),
            !_vm.loading
              ? _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.register.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("t")("Resend the verfication code")) +
                          " "
                      ),
                    ]),
                  ]
                )
              : _c("a", { attrs: { href: "#" } }, [
                  _c("b", [_vm._v(_vm._s(_vm._f("t")("Sending")) + "...")]),
                ]),
            _c("p", { staticClass: "mb-15" }),
            _c("p", { staticClass: "mb-15" }),
            !_vm.loading
              ? _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.step = 3
                        _vm.$forceUpdate()
                      },
                    },
                  },
                  [
                    _c("strong", { staticClass: "d-block" }, [
                      _vm._v(" " + _vm._s(_vm._f("t")("Continue ->")) + " "),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ])
      : _vm.step == 3
      ? _c(
          "div",
          [
            _c("verify-code-form", {
              attrs: {
                login: _vm.login,
                userData: _vm.userData,
                resend: _vm.register,
                callBack: _vm.afterVerify,
                loading: _vm.loading,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bullets" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }