<template>
    <a href="#" class="w-100 text-end" v-if="user" @click.prevent="logout">{{loading ? 'Signing out...' : 'Signout'|t}}</a>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed: {
        ...mapState("User", ["user"]),
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapActions("User", {doLogout: "logout"}),
        async logout()
        {
            if(this.loading) return false;
            if(window.confirm(this.$t('Do you want to logout ?')))
            {
                this.loading = true;
                this.$forceUpdate();
                await this.doLogout();
                this.$emit('logout', true);
                this.loading = false;
                this.$forceUpdate();
            }

            
        }
    },
}
</script>