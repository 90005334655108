export default {
    settings: {
        name: "العربية",
        key: "ar",
        dir: "rtl",
        xdir: "ltr",
        align: "right",
        xalign: "left"
    },
    ar: "العربية",
    en: "الانجليزية",
    
    //Words
    Welcome: "مرحباً",
    Country: "الدولة",
    "Email": "البريد الإلكترونى",
    Password: "كلمة المرور",
    Sending: "جارى الإرسال",


    //Phrases
    "Login to Business": "تسجيل دخول للمتجر",
    "Signout": "تسجيل خروج",
    "Signing out...": "جارى تسجيل الخروج...",
    "Do you want to Signout ?": "هل تريد تسجيل الخروج ؟",
    "Create a new Business": "إنشاء متجر جديد",    
    "Need Help?": "تريد المساعدة؟",
    "Content support": "الإتصال بالدعم",
    "Set up your Business": "إنشاء متجر جديد",
    "Business name": "إسم المتجر",
    "Business namespace": "عنوان رابط المتجر",
    "Website name": "رابط الموقع الإلكترونى",
    "I don't have a Website": "ليس لدى موقع إلكترونى",
    "Phone number": "رقم الهاتف",
    "Administrator Login info": "بيانات دخول مدير المتجر",
    "Get started with": "إبدأ بـ",
    "Sign in with": "تسجيل دخول بـ",
    "First name": "الإسم الأول",
    "Last name": "الإسم الأخير",
    "Use 8 or more characters with a mix of letters, numbers & symbols": "إستخدم 8 حروف أو مزيج من الحروف والارقام والحروف الخاصه",
    "Confirm Password": "تأكيد كلمة المرور",
    "I Agree to firnas": "الموافقة على",
    "Terms and conditions": "شروط وأحكام فرناس",
    "Sign in": "تسجيل دخول",
    "Sign up": "تسجيل جديد",
    "Get started": "إبدأ الآن",
    "Already have an account?": "لديك حساب بالفعل؟",
    "You don't have account?": "ليس لديك حساب ؟",
    "Verfiy your email Address": "تفعيل البريد الإلكترونى",
    "You're almost done": "على وشك الإنتهاء",
    "An email has been sent to": "تم إرسال بريداً إلى",
    "to verify your account": "لتفعيل حسابك",
    "In case you don't find the verfication email your account inbox within a few minutes, kindly consider checking your spam folder": 
        "فى حالة انك لم تجد البريد, من فضلك قم بالبحث عنه بمجلد الإسبام",
    "Resend the verfication code": "إعادة الإرسال",
    "Continue ->": "المتابعة ->",
    "Verify Phone Number": "تفعيل رقم الهاتف",
    "Verify your email address": "تفعيل البريد الإلكترونى",
    "Verify Code Sent to": "تم إرسال كود التفعيل على ",
    "Verifing code...": "جارى التفعيل...",
    "Verify": "تفعيل",
    "Please Enter Verify Code, If You Don't Receive it Click Resend button": 
        "عدم من فضلك أدخل كود التفعيل, فى حالة عدم إستلام الكود يرجى الضغط على إعادة الإرسال",
    "Signed in Successfully Wellcome": "مرحباً, تم تسجيل الدخول بنجاح",
    "Signed up Successfully Wellcome": "مرحباً,تم التسجيل بنجاح ",
    "Verify Code cannot be less than numbers please Fill all inputs by the sent code in right order": 
        "كود التفعيل لا يمكن ان يكون عدد قليل يرجى ملئ جميع الخانات"
}