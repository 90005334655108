<template>
    <span>
        {{'Need Help?'|t}} 
        <a href="#">
            {{'Content support'|t}}
        </a>
    </span>
</template>

<script>
export default {
    
}
</script>