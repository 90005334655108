import moment from "moment-timezone";
import RoutesList from "@/Store/Routes/RoutesList";
export default {
    async loadFromURL(dispatch, state, key, name, params, saveState)
    {
        try
        {
            if(key && state[key] && saveState)
            {
                return new Promise((resolve) => {
                    resolve(state[key]);
                });
            }
            else 
            {
                let res = await dispatch("Http/post", { name, params}, { root: true });
                if(saveState && key) state[key] = res.results;
                return res.results;
            }
        }
        catch(err) {dispatch("Errors/handle", err, {root:true})}
    },
    only(keys, obj)
    {
        
        if(!obj || !["array", "object"].includes(typeof obj) 
            || keys.constructor != Array) 
            {
                console.error("typeof obj", typeof obj, keys.constructor != Array);
                return obj
            }
        let isArray = obj.constructor == Array;
        let newObj = isArray ? [] : {};

        for(var k in obj)
        {
            if(keys.includes(k))
            {
                if(isArray) newObj.push(obj[k]);
                else newObj[k] = obj[k];
            }
        }
        // console.log("newObj", newObj);
        return newObj;
    },
    keyBy(list, keyName)
    {
        if(![Array, Object].includes(list.constructor)) return {};
        let newList = {};
        if(!keyName) keyName = "id";
        for(var i in list || [])
        {
            let row = list[i];   
            console.log("row[keyName]", row[keyName], keyName, row);
            if(row && row[keyName] !== undefined) newList[row[keyName]] = row;
        }
        console.log("newList", newList);
        return newList;
    },
    getDate(value, format)
    {
        if(!format) format = "YYYY-MM-DD hh:mm A";
        return moment(value).format(format);
    },
    getRouteByName(name, master)
    {
        var route = null;
        let routes = RoutesList;
        
        for(var i in routes)
        {
            route = routes[i];
            
            if(route.name == name) break;
        }

        return route;
    },
    StripTags(html)
    {
        return html.replace(/(<([^>]+)>)/gi, "");
    },
    findText (find, text) {
        var patt = new RegExp(find, 'gi');
        if (!text) text = '';
        
        if (text.constructor == Number) text = text.toString();
        else if ([Array, Object].includes(text.constructor)) {
            var found = false;
            for(var i in text) {
                var t = text[i];
                var f = this.findText(find, t);
                if (f) found = true;
            }
    
            return found;
        }
        else if (text.constructor != String) return false;
    
        var s = text.match(patt);
        return s && this.len(s) > 0;
    },
    len(context)
    {
        let length = 0;
        switch(context.constructor)
        {
            case Number:
                length = context.toString().length;
                break;
            case Array:
            case String:
                length = context.length;
                break;
            case Object:
                length = Object.keys(context).length;
                break;
        }

        return length;
    },
    filterList(find, list, focus)
    {
        if(![Array, Object].includes(list.constructor) || find.constructor != String) return [];
        let isObject = list.constructor == Object;
        let results = isObject ? {} : [];
        for(var i in list)
        {
            let searchIn = list[i];
            if(focus) searchIn = searchIn[focus];
            if(this.findText(find, searchIn))
            {
                if(isObject) results[i] = list[i];
                else results.push(list[i]);
            }
        }

        return results;
    },
    strToKey(keyName)
    {
        return keyName.trim().replace(/[^a-zA-Z_-]+/g, '_').toLowerCase();
    },
    baseName(url)
    {
        return url.split("://").pop().split("/")[0];
    }
}