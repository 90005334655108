var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      class: _vm.className || "w-100",
      attrs: { variant: "default", text: _vm.list[_vm.selected] },
    },
    _vm._l(_vm.list, function (lang, k, i) {
      return _c(
        "b-dropdown-item",
        {
          key: i,
          on: {
            click: function ($event) {
              return _vm.setLocale(k)
            },
          },
        },
        [_vm._v(" " + _vm._s(lang) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }