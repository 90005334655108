<template>
    <div 
        class="verify-phone-code-box p-2 "    
    >
        <h4 >
            {{ (userData.phone ? "Verify Phone Number" : "Verify your email address") | t }} <br />
        </h4>
        <div>
            <span >
                {{ $t("Verify Code Sent to") }}:</span>
            <small class="text-primary mx-1" dir="ltr">{{
                userData.phone ? formatePhoneNumber(userData.phone) : userData.email
            }}</small>
        </div>
        <div dir="ltr" id="otp" class="inputs d-flex flex-row justify-content-center mt-2" :class="error ? 'error' : ''">
            <input class="m-2 text-center form-control rounded phone_code_inputs" type="number" :id="`phone_code_input${i}`" :indx="i" v-model="code[i]"
                autocomplete="new-password"
                :disabled="loading2 || loading"
                onkeypress="return !['e'].includes(event.key)"
                onfocus="this.select()" @blur="inputenter($event)" @keyup="inputenter($event)" v-for="i in verificationCodeLength" :key="i" />
        </div>
        <small class="text-danger d-block mb-2 text-center" v-if="errorText">{{
            errorText
        }}</small>
        <div class="text-center mt-4">
            <b-button @click="verifyCode" variant="primary" class="btn btn-primary px-5" id="verifyCodeBtn"
                :class="btnClass || 'py-3 rounded-btn'" :disabled="loading2">
                {{ loading2 ? "Verifing code..." : "Verify" | t }}
                <b-spinner v-if="loading2" small class="mx-1" />
            </b-button>
        </div>
        <div class="mt-3 content d-flex justify-content-center align-items-center">            
            <a href="#" @click.prevent="resend" class="text-decoration-none text-dark mx-2">
                {{ (loading ? "Sending" : "Resend the verfication code") | t }}
                <span v-if="loading">...</span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            prevRoute: null,
            form: {},
            loading2: false,
            error: null,
            errorText: null,
            code: [],
        };
    },
    props: [
        "callBack",
        "login",
        "btnClass",
        "userData",
        "resend",
        "loading",
    ],
    computed: {
        ...mapState("Settings", ["settings"]),
        verificationCodeLength() {
            if (this.settings) {
                return this.settings.verification_code_length || 6;
            } else {
                return 6;
            }
        },
    },
    mounted() {
        this.loading2 = false;
        this.select();
    },
    methods: {
        ...mapActions("User", {
            verifyEmail: "verifyEmail",
        }),
        select(input)
        {
            setTimeout(() => {
                document.getElementById("phone_code_input"+(input || 1)).focus();
            }, 200);
        },  
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
       
        async verifyCode() {
            let breaking = false;
            for(var c = 1; c <= this.verificationCodeLength;c++)
            {                
                if(!this.code[c] || this.code[c] == "")
                {
                    breaking =true;
                    break;
                }
            }
            
            if(breaking || this.loading2) return false;
            this.loading2 = true;
            if (this.code.length - 1 != this.verificationCodeLength) {
                this.loading2 = false;
                this.error = true;
                this.errorText = this.$t(
                    "Please Enter Verify Code, If You Don't Receive it Click Resend button"
                );
            } else {
                this.loading2 = false;
                this.error = false;
            }
            if (!this.error) {
                this.loading2 = true;
                let form = this.login ? this.only(["email", "phone", "user_id"], this.userData) : this.userData;
                form.code = this.code.join("");
                if(!this.login) form.name = form.first_name + " " + form.last_name;
                console.log("formform>>", form, this.login);
                let res = await this[this.userData.phone ? "verifyPhone" : "verifyEmail"](form);
                if(res && res.token)
                {
                     if(this.callBack) this.callBack(res); 
                }                     
                else
                {
                    this.loading2 = false;
                    this.error = true;
                    // fisrtEl.focus();                        
                    this.code = [];
                    this.select();
                    this.errorText = this.$t("The verification code is incorrect");
                }
            } else {
                this.loading2 = false;
                this.errorText = this.$t(
                    "Verify Code cannot be less than numbers please Fill all inputs by the sent code in right order",
                    { length: this.verificationCodeLength }
                );
            }
        },
        allowedKeysOnly(event) {            
            return ![69].includes(event.keyCode);
        },
        inputenter(event) {
            this.errorText = null;
            let indexNumber = parseInt(event.srcElement.attributes.indx.value);
            
            if(!this.code[indexNumber] || this.code[indexNumber] == "")
            {
                this.code[indexNumber] = "";
                this.$forceUpdate();
                return false;
            }
            var next = indexNumber + 1;
            if(next > this.verificationCodeLength) next = "submit";
            var prev = indexNumber - 1;
            if(prev <= 0) prev = null;

            const nextEl = document.getElementById(`phone_code_input${next}`);
            const prevEl = prev ? document.getElementById(`phone_code_input${prev}`) : null;
            this.error = null;
            if (event.key === "Backspace") {
                event.target.value = "";
                if (prevEl) prevEl.focus();
            } else if (event.srcElement.value && event.srcElement.value.length > 1) {
               
                var value = event.srcElement.value.replace("e", "");
                for (var i in value) {
                    if(i >= this.verificationCodeLength) break;                    
                    var index = parseInt(i) + 1;
                    this.code[index] = value[i];
                }
                // console.log("this.code", {...this.code});
                // console.log("index < this.verificationCodeLength", index, this.verificationCodeLength, index < this.verificationCodeLength);
                if(index < this.verificationCodeLength)
                {
                    document.getElementById(`phone_code_input${index+1}`).focus();
                    for (var ii = index+1; ii <= this.verificationCodeLength;ii++) { 
                        this.code[ii] = "";
                    }
                }
                else 
                {
                    document.activeElement.blur();
                    this.verifyCode();
                }
                event.preventDefault();
                this.$forceUpdate();
            } 
            else if(event.srcElement.value == "e") this.code[event.srcElement.attributes.indx.value] = "";
            else if(event.srcElement.value && event.srcElement.value != "") {
                if (next == "submit") {
                    this.verifyCode();
                } else if (nextEl) {
                    nextEl.focus();
                    event.preventDefault();
                }
            }
            else this.code[indexNumber] = "";
        },
    },
};
</script>

<style></style>