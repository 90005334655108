import Register from "@/Pages/Register";
import Home from "@/Pages/Home";
import NotFound from "@/Pages/NotFound";
import Business from "@/Pages/Business";
import Itenrary from "@/Pages/Itenrary";
import DashboardRedirect from "@/Components/Auth/DashboardRedirect";

export default [
    {
        name: "Home",
        path: "/",
        component: Home,
    },    
    {
        name: "Register",
        path: "/register",
        component: Register,
        meta: { guest: true }
    },
    {
        name: "Business",
        path: "/business",
        component: Business,
        meta: { user: true }
    },
    // {
    //     name: "Itenrary",
    //     path: "/dashboard/itenrary/:id",
    //     component: Itenrary,
    //     // meta: { guest: true, user: true }
    // },
    {
        path: '/notfound',
        name: 'NotFound',
        component: NotFound,
    },
    {
        path: '*',
        component: DashboardRedirect 
    }
]