var render, staticRenderFns
import script from "./DashboardRedirect.vue?vue&type=script&lang=js&"
export * from "./DashboardRedirect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Code\\khattab\\firnas\\register\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bf8194e')) {
      api.createRecord('4bf8194e', component.options)
    } else {
      api.reload('4bf8194e', component.options)
    }
    
  }
}
component.options.__file = "src/Components/Auth/DashboardRedirect.vue"
export default component.exports